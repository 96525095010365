<template>
  <div class="Home">
    <Navbar title="Yajobici" />
    <div class="container-fluid p-0">
      <div class="section_1 mt-5 mb-1" style="margin-top: 125px !important">
        <!-- <div class="container">
                <marquee
                  class="li"
                  direction="”down”"
                  onmouseover="stop()"
                  onmouseout="start()"
                  style="font-size: 17px !important"
                >
                  <span style="color: #f7a51b">★★★</span>
                  <span style="color: #61c7f2"
                    >Notre application mobile est bientôt disponible</span
                  >
                  <span style="color: #f7a51b">★★★</span>
                </marquee>
              </div> -->
      </div>
      <!-- Header End -->

      <br /><br /><br />
      <p class="text-center mt-5">
        <strong style="font-size: 36px"
          >Trouvez le prestataire qui vous convient
        </strong>
        <br />
        <small>Parmi plusieurs postes ouverts</small>
      </p>

      <!-- Search Start  -->
      <div
        class="container-fluid mb-2 wow fadeIn"
        data-wow-delay="0.1s"
        style="padding: 35px"
      >
        <div class="container">
          <div class="row g-2 text-center">
            <div class="col-md-12">
              <div class="row g-2">
                <div class="col-md-2"></div>
                <div class="col-md-3">
                  <select
                    @change="onCategoryChange($event)"
                    class="form-select border-1"
                    required
                  >
                    <option value="" selected disabled>
                      --- Domaine d'activité ---
                    </option>
                    <option
                      v-for="index in categories"
                      :key="index.id"
                      :value="index.id"
                    >
                      {{ index.libelle }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <select
                    @change="onSubCategoryChange($event)"
                    class="form-select border-1"
                    placeholder=""
                    required
                  >
                    <optgroup :label="selectedCatName">
                      <option value="" selected disabled>--- Metier ---</option>
                      <option
                        v-for="subcat in subcategories"
                        :key="subcat.id"
                        :value="subcat.id"
                      >
                        {{ subcat.libelle }}
                      </option>
                    </optgroup>
                  </select>
                </div>
                <div class="col-md-2">
                  <button
                    @click.prevent="searchData"
                    class="btn btn-dark border-1 w-100"
                  >
                    Rechercher
                  </button>
                </div>
                <div class="col-md-2"></div>
              </div>
            </div>
          </div>

          <center
            v-if="!currentUser"
            style="text-align: center, font-size: 5px"
          >
            <small
              >Vous êtes un recruteur ? Vous proposez un service ? Êtes-vous à
              la recherche d'un formation ?
              <router-link to="/creation-de-compte" style="color: #f7a51b"
                >Inscrivez-vous</router-link
              >
              .
            </small>
          </center>

          <br />
          <div v-if="searchIsLoading" class="container">
            <div class="text-center">
              <strong style="color: #f7a51b">
                Recherche en cours veuillez patienté...
              </strong>
            </div>
          </div>
          <div v-else class="">
            <div
              class="container"
              v-if="searchResult && searchResult.length > 0"
            >
              <div class="row align-items-center">
                <div class="col-md-12 text-center">
                  <div class="mb-3 mt-3">
                    <small class="card-title"
                      >Resultat de la recherche
                      <span class="text-muted fw-normal ms-2"
                        >({{ searchResult.length }})</span
                      ></small
                    >
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center">
                <div
                  class="col-xl-3 col-sm-2 wow fadeInUp"
                  data-wow-delay="0.1s"
                  v-for="data in searchResult"
                  :key="data.prestataire.id"
                  @click.prevent="
                    redirectToDetailActeur(
                      data.prestataire.id,
                      data.metier.id,
                      data
                    )
                  "
                >
                  <!-- <router-link :to="{ name: 'detailActeur', params: { prestataireId: data.prestataire.id }}"> -->
                  <div class="sr-card" style="cursor: pointer !important">
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div>
                          <img
                            :src="data.prestataire.profile.photo"
                            alt=""
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </div>
                        <div class="flex-1 ms-3">
                          <h5 class="font-size-16 mb-1">
                            <a href="#" class="text-dark"
                              >{{ data.prestataire.profile.nom }}
                              {{ data.prestataire.profile.prenoms }}</a
                            >
                          </h5>
                          <span class="badge badge-soft-success mb-0">{{
                            data.metier.libelle
                          }}</span>
                        </div>
                      </div>
                      <div class="mt-3 pt-1">
                        <!-- <p class="text-muted mb-0"><i class="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i> {{ data.prestataire.profile.user.contact }}</p> -->
                        <!-- <p class="text-muted mb-0 mt-2"><i class="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i> {{ data.prestataire.profile.user.email }}</p> -->
                        <p class="text-muted mb-0 mt-2">
                          <i
                            class="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"
                          ></i>
                          {{ data.prestataire.profile.adresse }}
                        </p>
                        <p class="text-muted mb-0 mt-2">
                          <i
                            class="mdi mdi-road font-size-15 align-middle pe-2 text-primary"
                          ></i>
                          <span
                            v-if="data.distance_en_km != 'Non disponible'"
                            style="
                              color: #f7a51b !important;
                              font-size: 12px !important;
                            "
                            >À
                            <b
                              style="
                                color: #61c7f2 !important;
                                font-weight: bold !important;
                              "
                              >{{ data.distance_en_km }}</b
                            >
                            de vous</span
                          >
                          <span v-else style="color: red !important">{{
                            data.distance_en_km
                          }}</span>
                        </p>
                        <div class="mt-3">
                          {{ data.prestataire.description }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <!-- Search End -->
    </div>

    <!-- Category Start -->
    <div class="container-xxl mt-1 mb-5">
      <div class="container mt-5 mb-5">
        <br /><br /><br />
        <h1 class="text-center mt-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
          Nos domaines d'activité
        </h1>
        <div class="row justify-content-center g-4">
          <div
            v-for="index in categories"
            :key="index.id"
            class="col-sm-3 text-center wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <CategoryCard
              :catTitle="index.libelle"
              :catOfferNbr="index.id"
              :catImage="index.photo"
              :elId="index.id"
            />
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br /><br /><br />
    <!-- Category End -->

    <!-- publicité start -->
    <section class="mb-4" style="background-color: #f7a51b">
      <div class="container">
        <div class="row">
          <div class="col-md-4 mb-4 text-center ml-4">
            <img
              class="img-fluid"
              style="height: 500px !important; margin-top: -80px !important"
              src="../../assets/img/plays-removebg-preview.png"
              alt="image"
            />
          </div>
          <div class="col-md-5 py-5 text-center" style="align-self: center">
            <p class="text-light" style="font-size: 20px;">
              Pour une meilleure expérience, nous vous proposons de
              <br />
              <b
                >télécharger notre application mobile
                <span style="color: #2b3940">YAJOBICI</span> via
              </b>
              <br />
            </p>

            <div class="flex social-btns">
              <router-link class="app-btn blu flex vert" target="_blank" to="//apps.apple.com/us/app/yajobici/id1643542389">
                <i class="fab fa-apple"></i>
                <p>
                  <br> 
                  <span class="big-txt">App Store</span>
                </p>
              </router-link>

              <router-link class="app-btn blu flex vert" target="_blank" to="//play.google.com/store/apps/details?id=com.myrntemplate">
                <i class="fab fa-google-play"></i>
                <p>
                  <br>
                  <span class="big-txt">Google Play</span>
                </p>
              </router-link>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </section>
    <!-- publicité end -->

    <!-- Footer Start -->
    <Footer />
    <!-- Footer Ed -->
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import CategoryCard from "../../components/CategoryCard.vue";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
    CategoryCard,
  },
  data() {
    return {
      loading: false,
      categories: [],
      currentUser: null,
      selectedCatName: null,
      selectedSubCatName: null,
      subcategories: [],
      searchResult: null,
      searchIsLoading: false,
      subCatId: "",
      visitorLat: "",
      visitorLng: "",
    };
  },
  mounted() {
    this.categoriesList();
    this.locatorFetch();
    const target_copy = Object.assign(
      {},
      JSON.parse(localStorage.getItem("authUser"))
    );
    //console.log("DDD ", target_copy);
    if (target_copy.user) {
      this.currentUser = target_copy.user;
      this.userPhone = target_copy.user.phone;
    }
  },
  methods: {
    async categoriesList() {
      await this.$store.dispatch("staticdata/fetchCategorie").then(
        (response) => {
          this.categories = response.data;
        },
        (error) => {
          console.log("error--- ", error);
        }
      );
    },
    async redirectToDetailActeur(prestataireId, metierId, selectedUserInfos) {
      // Conversion de proxy en objet
      var arrayData = Object.assign({}, selectedUserInfos),
        metierDara = Object.assign({}, arrayData.metier),
        prestaDara = Object.assign({}, arrayData.prestataire),
        profileData = Object.assign({}, prestaDara.profile),
        apiUserDara = Object.assign({}, profileData.user),
        userReData = {
          full_name: profileData.nom + " " + profileData.prenoms,
          contact: apiUserDara.contact,
          adresse: profileData.adresse,
          photo_url: profileData.photo,
          categorie: prestaDara.categorie,
          metier: metierDara.libelle,
          description: arrayData.description,
        },
        dataToJson = JSON.stringify(userReData);

      var authData = Object.assign(
          {},
          JSON.parse(localStorage.getItem("authUser"))
        ),
        authToken = authData.token;
      if (authToken) {
        await localStorage.removeItem("selectedUserData");
        await localStorage.setItem("selectedUserData", dataToJson);

        router.push({
          name: "detailActeur",
          params: { prestataireId: prestataireId, metierId: metierId },
        });
      } else {
        router.push({ name: "Login" });
      }
    },

    async onCategoryChange(event) {
      if (event.target.value != "") {
        this.searchResult = [];
        this.selectedCatName =
          event.target.options[event.target.options.selectedIndex].text;
        await this.$store
          .dispatch("staticdata/fetchSubCategorie", event.target.value)
          .then(
            (response) => {
              this.subcategories = response.data;
            },
            (error) => {
              console.log("subcategories error--- ", error);
            }
          );
      }
    },
    async onSubCategoryChange(event) {
      if (event.target.value != "") {
        this.subCatId = event.target.value;
        this.searchResult = [];
        this.selectedSubCatName =
          event.target.options[event.target.options.selectedIndex].text;
      }
    },
    async searchData() {
      if (this.subCatId != "") {
        this.searchIsLoading = true;
        await this.$store
          .dispatch("staticdata/searchPrestataireBySubCategorie", this.subCatId)
          .then(
            (response) => {
              for (let index = 0; index < response.data.length; index++) {
                const element = response.data[index];

                const actorLat = element.prestataire.profile.localisation.lat,
                  actorLng = element.prestataire.profile.localisation.long;
                var _d =
                  this.visitorLat != "" && this.visitorLng
                    ? Math.round(
                        this._getDistanceFromLatLonInKm(
                          this.visitorLat,
                          this.visitorLng,
                          actorLat,
                          actorLng
                        ) * 100
                      ) /
                        100 +
                      " Km"
                    : "Non disponible";
                element.distance_en_km = _d;
              }
              this.searchResult = response.data;
              //console.log(response.data);
              this.searchIsLoading = false;
              if (response.data.length == 0) {
                Swal.fire({
                  title: "Désolé :)",
                  html:
                    "<p>Nous n'avons pas trouvé de prestataire pour le metier : <b style='color:#F7A51B'>" +
                    this.selectedSubCatName +
                    "</b> de la catégorie : <b>" +
                    this.selectedCatName +
                    "</b></p>",
                  icon: "warning",
                });
              }
            },
            (error) => {
              this.searchIsLoading = false;
              console.log("subcategories error--- ", error);
            }
          );
      }
    },

    _getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in kilometers
      var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in KM
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    locatorFetch() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.visitorLat = position.coords.latitude;
          this.visitorLng = position.coords.longitude;
          // console.log(position.coords.latitude);
          // console.log(position.coords.longitude);
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
  },
};

</script>


<style scoped>
.sr-card {
  margin-bottom: 24px;
  box-shadow: 0 2px 3px #e4e8f0;
}
.sr-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff0f2;
  border-bottom: 4px solid #61c7f2;
  border-radius: 1rem;
}
.avatar-md {
  height: 80px !important;
  width: 80px !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #f1f3f7;
  border: 1px solid #eff0f2;
  border-radius: 0.75rem;
}
.avatar-title {
  align-items: center;
  background-color: #61c7f2;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.bg-soft-primary {
  background-color: rgba(59, 118, 225, 0.25) !important;
}
a {
  text-decoration: none !important;
}
.badge-soft-danger {
  color: #f56e6e !important;
  background-color: rgba(245, 110, 110, 0.1);
}
.badge-soft-success {
  color: #f7a51b !important;
  background-color: rgba(99, 173, 111, 0.1);
}
.mb-0 {
  margin-bottom: 0 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
}
.btnmobile {
  border: 2px solid #000 !important;
  background-color: #000 !important;
}
.btmobile {
  border: 2px solid #000 !important;
  background-color: #000 !important;
}

.btmobile svg {
  width: 21px !important;
}
.btnmobile svg {
  width: 25px !important;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-btn {
  width: 45%;
  max-width: 160px;
  color: #fff;
  margin: 20px 10px;
  text-align: left;
  border-radius: 5px;
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
}
.app-btn.blu {
  background-color: #101010;
  transition: background-color 0.25s linear;
}
.app-btn.blu:hover {
  color: #fff;
  border-color: #fff !important;
  background-color: #61c7f2;
}
.app-btn i {
  width: 20%;
  text-align: center;
  font-size: 25px;
  margin-right: 7px;
}
.app-btn .big-txt {
  font-size: 17px;
  text-transform: capitalize;
}
</style>