import axios from "axios";
import { baseURL } from "../config/apiBase";

class AuthService {
    login(user) {
        return axios
            .post(baseURL + "login", {
                phone: user.phone,
                password: user.password,
            })
            .then((response) => {
                if (response.data.data.token) {
                    localStorage.setItem("authUser", JSON.stringify(response.data.data));
                }
                return response.data;
            });
    }
    otpVerification(d) {
        var url =
            baseURL +
            "otp-verify?code_otp=" +
            d.code_otp +
            "&phone=" +
            d.phone +
            "&password=" +
            d.password;
        return axios.put(url).then((response) => {
            if (response.data.data.token) {
                localStorage.setItem("authUser", JSON.stringify(response.data.data));
            }
            return response.data;
        });
    }
    sendForgotPasswordRequest(phoneNumber) {
        return axios
            .post(baseURL + "password-forgot", {
                phone: "225" + phoneNumber,
            })
            .then((response) => {
                return response.data;
            });
    }
    otpVerificationToResetPassword(codeOtp) {
        return axios
            .post(baseURL + "password-forgot/verified-otp", {
                code_otp: codeOtp,
            })
            .then((response) => {
                return response.data;
            });
    }
    passwordReset(d) {
        return axios
            .post(baseURL + "password-reset", {
                phone: d.phoneNumber,
                password: d.newPassword,
            })
            .then((response) => {
                return response.data;
            });
    }
    logout() {
        localStorage.removeItem("authUser");
        return axios.get(baseURL + "logout").then((response) => {
            return response.data;
        });
    }
    resendOtp(d) {
        return axios.post(baseURL + "resend-otp", d).then((response) => {
            return response.data;
        });
    }
}

export default new AuthService();