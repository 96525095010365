import RegisterService from '../services/register.service';

export const register = {
    namespaced: true,
    state: {
        message: null
    },
    actions: {
        register({ commit }, user) {
            if (user.typeForm === "Recruteur") {
                return RegisterService.createRecruteur(user)
                    .then(
                        response => {
                            commit('registerSuccess');
                            return Promise.resolve(response);
                        },
                        error => {
                            commit('registerFailure');
                            return Promise.reject(error);
                        }
                    );
            } else {
                return RegisterService.createPrestataire(user)
                    .then(
                        response => {
                            commit('registerSuccess');
                            //console.log("From Module ", response);
                            return Promise.resolve(response);
                        },
                        error => {
                            commit('registerFailure');
                            return Promise.reject(error);
                        }
                    );
            }
        },
    },
    mutations: {
        registerSuccess(state) {
            state.message = "Ok";
        },
        registerFailure(state) {
            state.message = null;
        }
    }
};