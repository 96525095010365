<template>
  <div class="Login bg-white">
    <div class="bg-white">
      <div class="login-root">
        <div
          class="box-root flex-flex flex-direction--column"
          style="min-height: 100vh; flex-grow: 1"
        >
          <div class="loginbackground box-background--white padding-top--64">
            <div class="loginbackground-gridContainer"></div>
          </div>

          <div
            class="box-root padding-top--24 flex-flex flex-direction--column mt-5"
            style="flex-grow: 1; z-index: 9"
          >
            <div class="formbg-outer mt-4">
              <div class="formbg">
                <div class="formbg-inner padding-horizontal--48">
                  <div class="row mt-2 mb-2">
                    <div class="col-md-12">
                      <div class="text-center">
                        <h4 v-if="showPhoneInput">
                          Réinitialisation de mot de passe
                        </h4>
                        <h4 v-if="showOtpInput">Vérification du OTP</h4>
                        <h4 v-if="showNewPwdInput">
                          Définissez votre nouveau mot de passe
                        </h4>
                        <div v-if="showPhoneInput" class="mt-3">
                          <small>
                            <p>
                              Veuillez renseigner votre numero de téléphone dans
                              le champ ci-dessous s'il vous plaît.
                            </p>
                          </small>
                        </div>
                        <div v-if="showOtpInput" class="mt-3">
                          <small>
                            <p>
                              Reenseignez le code que vous avez reçu par SMS
                              <strong>{{ optString }}</strong>
                            </p>
                          </small>
                        </div>
                        <div v-if="showNewPwdInput" class="mt-3">
                          <small>
                            <p>Renseignez votre nouveau mot de psse</p>
                          </small>
                        </div>
                        <!-- <div class="text-center">
                          <BaseTimer />
                        </div> -->
                        <!-- numero de telephone pour recevoir l'otp de vérification -->
                        <div
                          v-if="showPhoneInput"
                          class="send-forgot-pwd-form1"
                        >
                          <form @submit.prevent="sendForgotPasswordForm">
                            <div class="mt-5 mb-3">
                              <input
                                v-model="phoneNumber"
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="N° de Téléphone"
                                required
                              />
                            </div>

                            <button
                              v-if="isLoading"
                              type="submit"
                              class="btn btn-warning w-100 mb-5 disabled"
                              style="
                                background-color: #f7a51b !important;
                                color: #ffffff;
                              "
                            >
                              Opération en cours ...
                            </button>
                            <button
                              v-else
                              type="submit"
                              class="btn btn-warning w-100 mb-5"
                              style="
                                background-color: #f7a51b !important;
                                color: #ffffff;
                              "
                            >
                              Envoyer
                            </button>
                          </form>
                        </div>
                        <!-- numero de telephone pour recevoir l'otp de vérification -->

                        <!--
                          otp de vérification 
                          v-if="showOtpInput"
                        -->
                        <div
                          v-if="showOtpInput"
                          class="opt-verify-form-pwd text-center py-4"
                        >
                          <form @submit.prevent="sendVerifyOtp">
                            <div class="row">
                              <div class="col-md-2"></div>
                              <div class="col-md-8">
                                <VOtpInput
                                  ref="otpInput"
                                  input-classes="otp-input"
                                  separator="-"
                                  :num-inputs="4"
                                  :should-auto-focus="true"
                                  :is-input-num="true"
                                  @on-change="handleOnChange"
                                  @on-complete="handleOnComplete"
                                />
                              </div>
                              <div class="col-md-2"></div>
                            </div>
                            <br />
                            <button
                              v-if="isLoading"
                              type="submit"
                              class="btn btn-warning w-100 mb-5 disabled"
                              style="
                                background-color: #f7a51b !important;
                                color: #ffffff;
                              "
                            >
                              Opération en cours ...
                            </button>
                            <button
                              v-else
                              type="submit"
                              class="btn btn-warning w-100 mb-2"
                              style="
                                background-color: #f7a51b !important;
                                color: #ffffff;
                              "
                            >
                              Verifier l'opt
                            </button>
                            <div class="text-center">
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10">
                                  <BaseTimer
                                    :canStartTimer="showOtpInput"
                                    :user-phone="phoneNumber"
                                  />
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <!--otp de vérification -->

                        <!-- form Réinitialisation de mot de passe -->
                        <div v-if="showNewPwdInput" class="new-pwd-form">
                          <form @submit.prevent="setNewPassword">
                            <div class="mt-5 mb-3">
                              <input
                                type="text"
                                class="form-control"
                                id="phoneInput"
                                readonly
                                v-model="phoneNumber"
                                placeholder="Numéro de téléphone"
                              />
                            </div>
                            <div class="mt-2 mb-3">
                              <input
                                type="password"
                                class="form-control"
                                id="newPwd"
                                v-model="newPwd"
                                placeholder="Nouveau mot de passe"
                                required
                              />
                            </div>

                            <button
                              v-if="isLoading"
                              type="submit"
                              class="btn btn-warning w-100 mb-5 disabled"
                              style="
                                background-color: #f7a51b !important;
                                color: #ffffff;
                              "
                            >
                              Opération en cours ...
                            </button>

                            <button
                              v-else
                              type="submit"
                              class="btn btn-warning w-100 mb-5"
                              style="
                                background-color: #f7a51b !important;
                                color: #ffffff;
                              "
                            >
                              Valider
                            </button>
                          </form>
                        </div>
                        <!-- form Réinitialisation de mot de passe -->
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mt-1">
                      <div class="text-center">
                        <router-link to="/">
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-danger w-100"
                          >
                            <font-awesome-icon icon="home" />&nbsp;&nbsp;Accueil
                          </button>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-md-6 mt-1">
                      <div class="text-center">
                        <router-link to="/creation-de-compte">
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-info w-100"
                          >
                            <font-awesome-icon
                              icon="user-plus"
                            />&nbsp;&nbsp;Inscription
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Navbar from "../../components/Navbar.vue";
import BaseTimer from "../../components/BaseTimer.vue";
import Swal from "sweetalert2";
import { ref } from "vue";
import router from "@/router";
import VOtpInput from "vue3-otp-input";
import { useToast } from "vue-toastification";
export default {
  name: "Forgot_password",
  components: {
    //Navbar
    VOtpInput,
    BaseTimer,
  },
  data() {
    return {
      phoneNumber: "",
      optValue: "",
      newPwd: "",
      optString: "",
      showPhoneInput: true,
      showOtpInput: false,
      showNewPwdInput: false,
      isLoading: false,
      countDown: 10,
    };
  },
  setup() {
    const otpInput = ref(null);
    const toast = useToast();
    const clearInput = () => {
      otpInput.value.clearInput();
    };

    return { clearInput, otpInput, toast };
  },

  methods: {
    handleOnComplete(value) {
      this.optValue = value;
    },
    async sendVerifyOtp() {
      this.isLoading = true;
      await this.$store.dispatch("auth/forgotPwdVerifyOpt", this.optValue).then(
        (response) => {
          this.isLoading = false;
          this.showPhoneInput = false;
          this.showOtpInput = false;
          this.showNewPwdInput = true;
          //2250749060536;
          console.log(response);
          this.phoneNumber = "2250749060536"; //response.data.phone;
          this.toast.success("OTP Validé", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.isLoading = false;

          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },
    async resendOtpCode() {},
    async setNewPassword() {
      var data = {
        phoneNumber: this.phoneNumber,
        newPassword: this.newPwd,
      };

      this.isLoading = true;
      await this.$store.dispatch("auth/forgotPwdSetNewPassword", data).then(
        (response) => {
          this.isLoading = false;
          this.showPhoneInput = false;
          this.showOtpInput = false;
          this.showNewPwdInput = true;
          this.toast.success(response.message, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          router.push({ name: "Login" });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.isLoading = false;

          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },
    async sendForgotPasswordForm() {
      this.isLoading = true;
      await this.$store.dispatch("auth/forgotPwd", this.phoneNumber).then(
        (response) => {
          console.log("response ", response);
          this.isLoading = false;
          this.showPhoneInput = false;
          this.showOtpInput = true;
          this.phoneNumber = response.phone;
          this.optString = response.code_otp;

          this.toast.success("Un sms vient d'être envoyé sur votre numéro", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.isLoading = false;

          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  word-wrap: break-word;
}
body {
  min-height: 100%;
  background-color: #aeabab !important;
}
h1 {
  letter-spacing: -1px;
}
a {
  color: #61c7f2;
  text-decoration: unset;
}
.login-root {
  background: #fff;
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.loginbackground {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.flex-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.center-center {
  align-items: center;
  justify-content: center;
}
.box-root {
  box-sizing: border-box;
}
.flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}
.box-background--blue {
  background-color: #61c7f2;
}
.box-background--white {
  background-color: #ffffff;
}
.box-background--blue800 {
  background-color: #212d63;
}
.box-background--gray100 {
  background-color: #e3e8ee;
}
.box-background--cyan200 {
  background-color: #7fd3ed;
}
.padding-top--64 {
  padding-top: 64px;
}
.padding-top--24 {
  padding-top: 24px;
}
.padding-top--48 {
  padding-top: 48px;
}
.padding-bottom--24 {
  padding-bottom: 24px;
}
.padding-horizontal--48 {
  padding: 48px;
}
.padding-bottom--15 {
  padding-bottom: 15px;
}

.flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.formbg {
  margin: 0px auto;
  width: 100%;
  max-width: 448px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}
span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}
label {
  margin-bottom: 10px;
}
.reset-pass a,
label {
  font-size: 14px;
  font-weight: 600;
  display: block;
}
.reset-pass > a {
  text-align: right;
  margin-bottom: 10px;
}
.grid--50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.loginbackground-gridContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [left-gutter] 1fr [end];
  grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
  -ms-grid-rows: [top] 1fr [top-gutter] (64px) [bottom-gutter] 1fr [bottom];
  grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
  justify-content: center;
  margin: 0 -2%;
  transform: rotate(-12deg) skew(-12deg);
}

.field input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: 1px solid #acacac;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: #61c7f2 0px 0px 0px 0px;
}

@keyframes animationLeftRight {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes animationRightLeft {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
