<template>
  <div>
    <input type="checkbox" id="sidebar-toggle" />
    <UserSideBar :typeUser="typeActeur" />

    <div class="main-content">
      <UserNavbar :data="userData" />

      <main>
        <div class="row mt-5">
          <div class="col-md-3"></div>

          <div
            class="col-lg-6"
            style="padding-top: 110px !important; font-size: 19px !important"
          >
            <!-- Accueil prestataire start -->
            <p v-if="typeActeur == 'prestataire'" class="text-center mt-5">
              Pour toute action d'<span style="color: #61c7f2">ajout</span> ou
              de <span style="color: #f7a51b">modification</span> de vos
              services, veuillez utiliser notre
              <span style="color: #61c7f2">application mobile</span>
              téléchargeable via: <br /><br />

              <span class="mobile m-5">
                <router-link target="_blank" to="//apps.apple.com/us/app/yajobici/id1643542389">
                  <button class="btmobile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="#ffffff"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                    App Store
                  </button>
                </router-link>
              </span>

              <span class="mobile">
                <router-link target="_blank" to="//play.google.com/store/apps/details?id=com.myrntemplate">
                  <button class="btnmobile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#ffffff"
                        d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                      />
                    </svg>
                    Google Play
                  </button>
                </router-link>
              </span>
            </p>
            <!-- Accueil prestataire end -->

            <!-- Accueil Recruteur start -->
            <p v-else class="text-center mt-5">
              Pour effectuer une
              <span style="color: #61c7f2">demande</span> ou autre
              <span style="color: #f7a51b">action</span>, <br />
              veuillez utiliser notre
              <span style="color: #61c7f2">application mobile</span>
              téléchargeable via: <br /><br />

              <span class="mobile m-5">
                <router-link to="">
                  <button class="btmobile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="#ffffff"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                    App Store
                  </button>
                </router-link>
              </span>

              <span class="mobile">
                <router-link to="">
                  <button class="btnmobile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#ffffff"
                        d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                      />
                    </svg>
                    Google Play
                  </button>
                </router-link>
              </span>
            </p>
            <!-- Accueil Recruteur end -->
          </div>

          <div class="col-md-3"></div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import UserNavbar from "../../components/UserNavbar.vue";
import UserSideBar from "../../components/UserSideBar.vue";
import { useToast } from "vue-toastification";

export default {
  name: "Dashboard",
  components: {
    UserNavbar,
    UserSideBar,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading: false,
      userData: null,
      userType: "",
      typeActeur: "",
    };
  },
  mounted() {
    this.fetchUserInfos();
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    async fetchUserInfos() {
      var authData = Object.assign(
        {},
        JSON.parse(localStorage.getItem("authUser"))
      );
      this.userType = authData.user.type;
      this.typeActeur = authData.user.acteur;

      await this.$store.dispatch("user/retrieveUserInfo").then(
        (response) => {
          this.userData = response.data;
        },
        (error) => {
          console.log("subcategories error--- ", error);
        }
      );
    },
  },
};
</script>

<style scoped>
.btnmobile {
  border: 2px solid #f7a51b !important;
  background-color: #f7a51b !important;
}
.btmobile {
  border: 2px solid #61c7f2 !important;
  background-color: #61c7f2 !important;
}

.btmobile svg {
  width: 21px !important;
}
.btnmobile svg {
  width: 25px !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

:root {
  --main-color: #027581;
  --color-dark: #1d2231;
  --text-grey: #8390a2;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  box-sizing: border-box;
}

#sidebar-toggle {
  display: none;
}

.sidebar {
  height: 100%;
  width: 200px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: #61c7f2 !important;
  color: #fff;
  overflow-y: auto;
  transition: width 500ms;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0rem 1rem;
}

.sidebar-menu {
  padding: 1rem;
}

.sidebar li {
  margin-bottom: 1.5rem;
}

.sidebar a {
  color: #fff;
  font-size: 0.8rem;
}

.sidebar a span:last-child {
  padding-left: 0.6rem;
}

#sidebar-toggle:checked ~ .sidebar .sidebar-header h3 span,
#sidebar-toggle:checked ~ .sidebar li span:last-child {
  display: none;
}

#sidebar-toggle:checked ~ .sidebar .sidebar-header,
#sidebar-toggle:checked ~ .sidebar li {
  display: flex;
  justify-content: center;
}

#sidebar-toggle:checked ~ .main-content {
  margin-left: 60px;
}

#sidebar-toggle:checked ~ .main-content header {
  left: 60px;
  width: calc(100% - 60px);
}

.main-content {
  position: relative;
  margin-left: 200px;
  transition: margin-left 500ms;
}

header {
  position: fixed;
  left: 200px;
  top: 0;
  z-index: 100;
  width: calc(100% - 200px);
  background: #fff;
  height: 60px;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  transition: left 500ms;
}

.search-wrapper {
  display: flex;
  align-items: center;
}

.search-wrapper input {
  border: 0;
  outline: 0;
  padding: 1rem;
  height: 38px;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons span,
.social-icons div {
  margin-left: 1.2rem;
}

.social-icons div {
  height: 38px;
  width: 38px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(http://195.154.51.46:8117/storage/6/E46F4F25-823A-4693-83C7-924B796DF520.jpg);
  border-radius: 50%;
}

main {
  margin-top: 60px;
  background: #f1f5f9;
  min-height: 90vh;
  padding: 1rem 3rem;
}

.badge {
  padding: 0.2rem 1rem;
  border-radius: 20px;
  font-weight: 700;
  font-size: 0.7rem;
}

.badge.success {
  background: #def7ec;
  color: var(--main-color);
}

.badge.warning {
  background: #f0f6b2;
  color: orange;
}

.td-team {
  display: flex;
  align-items: center;
}

.img-1,
.img-2,
.img-3 {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  margin-left: -15px;
  border: 3px solid #efefef;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-1 {
  background-image: url(../../assets/img/yajobici.png);
}

.img-2 {
  background-image: url(../../assets/img/yajobici.png);
}

.img-3 {
  background-image: url(../../assets/img/yajobici.png);
}

.summary-card {
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.summary-single {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.summary-single span {
  font-size: 1.5rem;
  color: #777;
  padding-right: 1rem;
}

.summary-single h5 {
  color: var(--main-color);
  font-size: 1.1rem;
  margin-bottom: 0rem !important;
}

.summary-single small {
  font-weight: 700;
  color: var(--text-grey);
}

.bday-flex {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

.bday-card {
  padding: 1rem;
}

.bday-img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 3px solid #efefef;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../assets/img/yajobici.png);
  margin-right: 0.7rem;
}

.text-center {
  text-align: center;
}

.text-center button {
  background: var(--main-color);
  color: #fff;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  padding: 0.4rem 1rem;
}

.table-responsive {
  overflow-x: auto;
}

@media only screen and (max-width: 1200px) {
  .sidebar {
    width: 60px;
    z-index: 150;
  }

  .sidebar .sidebar-header h3 span,
  .sidebar li span:last-child {
    display: none;
  }

  .sidebar .sidebar-header,
  .sidebar li {
    display: flex;
    justify-content: center;
  }

  .main-content {
    margin-left: 60px;
  }

  .main-content header {
    left: 60px;
    width: calc(100% - 60px);
  }

  #sidebar-toggle:checked ~ .sidebar {
    width: 240px;
  }

  #sidebar-toggle:checked ~ .sidebar .sidebar-header h3 span,
  #sidebar-toggle:checked ~ .sidebar li span:last-child {
    display: inline;
  }

  #sidebar-toggle:checked ~ .sidebar .sidebar-header {
    display: flex;
    justify-content: space-between;
  }

  #sidebar-toggle:checked ~ .sidebar li {
    display: block;
  }

  #sidebar-toggle:checked ~ .main-content {
    margin-left: 60px;
  }

  #sidebar-toggle:checked ~ .main-content header {
    left: 60px;
  }
}

@media only screen and (max-width: 860px) {
  .dash-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-single {
    margin-bottom: 1rem;
  }

  .activity-grid {
    display: block;
  }

  .summary {
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .dash-cards {
    grid-template-columns: 100%;
  }
}

@media only screen and (max-width: 450px) {
  main {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
