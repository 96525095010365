import axios from "axios";
import { baseURL } from "../config/apiBase";

class UserService {
    changeLocation(locationData) {
        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;

        return axios
            .put(
                baseURL +
                "location/update?localisation=" +
                locationData.localisation +
                "&addresse=" +
                locationData.addresse, { action: "dashboard" }, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + authToken,
                    },
                }
            )
            .then((response) => {
                return response.data;
            });
    }
    changePassword(d) {
        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;
        return axios
            .post(baseURL + "change-password", d, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                return response.data;
            });
    }
    editInformation(data) {
        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;
        return axios
            .put(baseURL + "user/update", data, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                return response.data;
            });
    }
    avatarUpdate(data) {
        let formData = new FormData();

        for (var key of Object.keys(data)) {
            formData.append(key, data[key]);
        }

        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;
        return axios
            .post(baseURL + "user/avatar-update", formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    }
    getAuthUserInfos() {
        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;
        //console.log("authData ", authData);
        return axios
            .get(baseURL + "user", {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                return response.data;
            });
    }
    getAuthUserServices() {
        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;

        return axios
            .get(baseURL + "services/liste", {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                return response.data;
            });
    }
    contactUs(d) {
        return axios.post(baseURL + "contact-us", d).then((response) => {
            return response.data;
        });
    }
    userAccountDeletionVerification(password) {
        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;

        return axios
            .post(
                baseURL + "delete-account/verify", {
                    current_password: password,
                }, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            )
            .then((response) => {
                return response.data;
            });
    }
    userAccountDeletionConfirmation() {
        var authData = Object.assign({},
                JSON.parse(localStorage.getItem("authUser"))
            ),
            authToken = authData.token;

        return axios
            .post(
                baseURL + "delete-account/confirm", {
                    //"current_password": password
                }, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            )
            .then((response) => {
                return response.data;
            });
    }
}

export default new UserService();