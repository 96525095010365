<template>
    <div class="container-fluid text-white-50 footer pt-5 mt-5 wow fadeIn" style="background-color: #61C7F2" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-4 col-md-6">
                    <h5 class="text-white mb-4">Yajobici</h5>
                    <p>Yajobici.com est un portail professionnel dédié à l'emploi et au recrutement en Afrique. <br> Vous pouvez télécharger notre application via:</p>
                    <div class="d-flex">
                        <router-link class="btn btn-outline-light btn-store" target="_blank" to="//apps.apple.com/us/app/yajobici/id1643542389">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="#ffffff"
                                d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                              />
                          </svg>App Store
                        </router-link>
                        <router-link class="btn btn-outline-light btn-store" target="_blank" to="//play.google.com/store/apps/details?id=com.myrntemplate">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            >
                            <path
                              fill="#ffffff"
                              d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                            />
                          </svg>Google Play
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <h5 class="text-white mb-4">Liens</h5>
                    <router-link class="btn btn-link text-white-50" to="/a-propos-de-nous">A propos</router-link>
                    <router-link class="btn btn-link text-white-50" to="/nos-services">Nos Services</router-link>
                    <router-link class="btn btn-link text-white-50" to="/contactez-nous">Contactez-nous</router-link>
                    <router-link class="btn btn-link text-white-50" to="/nos-conditions-generales">Conditions générales</router-link>
                    <router-link class="btn btn-link text-white-50" to="/supprimer-mon-compte">Comment supprimer mon compte ?</router-link>
                </div>
                <div class="col-lg-4 col-md-6">
                    <h5 class="text-white mb-4">Contacts</h5>
                    <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+225 0767561377</p>
                    <p class="mb-2"><i class="fa fa-envelope me-3"></i>yajobici@agilestelecoms.com</p>
                    <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>Angré 8ème Tranche, pres de l'école <br> la Fontaine Blanche</p>
                    <div class="d-flex pt-2">
                        <router-link class="btn btn-outline-light btn-social" to=""><i class="fab fa-facebook-f"></i></router-link>
                        <router-link class="btn btn-outline-light btn-social" to=""><i class="fab fa-youtube"></i></router-link>
                        <router-link class="btn btn-outline-light btn-social" to=""><i class="fab fa-linkedin-in"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a class="border-bottom" href="#">yajobici</a>, Tous droits réservés.
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        Design par <a class="border-bottom" href="https:/agilestelecoms.com">Agiles Telecoms</a>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!--  -->
    <input type="checkbox" id="click">
    <label for="click">
        <i class="far">&#xf086;</i>
        <i class="fas fa-times"></i>
    </label>
    <div class="wrapper">
        <div class="head-text">Service Assistance</div>
        <div class="chat-box">
            <div class="desc-text">Bonjour, <br> veuillez laisser votre message s'il vous plait. <br> Nous vous contacterons dès que possible.</div>
            <div class="icon" style="padding-left: 70px !important;">
                <i class="fab fa-facebook-messenger m-3" 
                style="height: 55px;width: 55px;background: -webkit-linear-gradient(left, #a445b2, #fa4299);
                text-align: center;line-height: 55px;border-radius: 50px;font-size: 30px;color: #fff;cursor: pointer;"></i>
              <router-link to="//wa.me/2250555736487" target="_blank">
              <!-- <router-link to="https://wa.me/message/KREYYJAEWCM2K1" target="_blank"> -->
                <i class="fab fa-whatsapp m-3" style="height: 55px;width: 55px;background: green; text-align: center;
                line-height: 55px;border-radius: 50px;font-size: 30px;color: #fff;cursor: pointer;"></i>
              </router-link>
            </div>
        
        </div>
    </div>
    <!--  -->




</template>
<script>
export default {
  name: 'Footer',
  props: {}
}
</script>

<style scoped>

.btn-store svg {
  width: 20px !important;
}
.btn-store svg:hover {
  color: orange !important;
}

#click {
  display: none;
}
label {
  position: fixed;
  right: 30px;
  bottom: 15px;
  height: 55px;
  width: 55px;
  background: -webkit-linear-gradient(left, #F7A51B, #61C7F2);
  text-align: center;
  line-height: 55px;
  border-radius: 50px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
label i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
}
label i.fas {
  opacity: 0;
  pointer-events: none;
}
#click:checked ~ label i.fas {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) rotate(180deg);
}
#click:checked ~ label i.far {
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) rotate(180deg);
}
.wrapper {
  position: fixed;
  right: 30px;
  bottom: 0px;
  max-width: 400px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
#click:checked ~ .wrapper {
  opacity: 1;
  bottom: 85px;
  pointer-events: auto;
}
.wrapper .head-text {
  line-height: 60px;
  color: #fff;
  border-radius: 15px 15px 0 0;
  padding: 0 20px;
  font-weight: 500;
  font-size: 20px;
  background: -webkit-linear-gradient(left, #F7A51B, #61C7F2);
}
.wrapper .chat-box {
  padding: 20px;
  width: 100%;
}
.chat-box .desc-text {
  color: #515365e3;
  /* text-align: center; */
  line-height: 25px;
  font-size: 17px;
  font-weight: 500;
}
</style>