import { createWebHistory, createRouter } from "vue-router";
import Home from "./views/home/Home.vue";
import Register from "./views/register/Register.vue";
import Login from "./views/login/Login.vue";
import Forgot_password from "./views/login/Forgot_password.vue";
import Service from "./views/nosservices/Service.vue";
import Cat_service from "./views/nosservices/Cat_service.vue";
import Detail_service from "./views/nosservices/Detail_service.vue";
import Detail_acteur from "./views/home/Detail_acteur.vue";
import Delete_account from "./views/home/Delete_account.vue";
import Dashboard from "./views/auth/Dashboard.vue";
import User_profil from "./views/auth/User_profil.vue";
import User_services from "./views/auth/User_services.vue";
import About from "./views/quicklinks/About.vue";
import condition from "./views/quicklinks/condition.vue";
import contact from "./views/quicklinks/contact.vue";


const routes = [{
        path: "/",
        name: "home",
        component: Home,
        meta: { title: 'Accueil' }
    },
    {
        path: "/a-propos-de-nous",
        name: "about",
        component: About,
        meta: { title: 'A propos de nous' }
    },
    {
        path: "/contactez-nous",
        name: "contact",
        component: contact,
        meta: { title: 'Nous contacter' }
    },
    {
        path: "/nos-conditions-generales",
        name: "condition",
        component: condition,
        meta: { title: 'Condition Générale' }
    },
    {
        path: "/details/:prestataireId/metier/:metierId",
        name: "detailActeur",
        component: Detail_acteur,
        meta: { title: 'Détail du prestataire', authRequired: 'true' }
    },
    {
        path: "/supprimer-mon-compte",
        name: "deleteAccount",
        component: Delete_account,
        meta: { title: 'Supprimer mon compte'}
    },
    {
        path: "/nos-services",
        name: "service",
        component: Service,
        meta: { title: 'Nos services' }
    },
    {
        path: "/detail-du-service/:categorieId/:categorieName",
        name: "detailService",
        component: Detail_service,
        meta: { title: 'Détails de nos services' }
    },
    {
        path: "/categorie-du-service/:subcatId/:subcatName",
        name: "categorieService",
        component: Cat_service,
        meta: { title: 'Les catégories de nos services' }
    },

    {
        path: "/creation-de-compte",
        name: "create-de-compte",
        component: Register,
        meta: { title: 'Inscription' }
    },
    {
        path: "/connexion",
        name: "Login",
        component: Login,
        meta: { title: 'Connexion' }
    },
    {
        path: "/reinitialisation-de-mot-de-passe",
        name: "Forgot_password",
        component: Forgot_password,
        meta: { title: 'Réinitialisation de mot de passe' }
    },
    {
        path: "/dashboard-espace-utilisateur",
        name: "dashboard",
        component: Dashboard,
        meta: { title: 'Dashboard utilisateur', authRequired: 'true' }
    },
    {
        path: "/mes-services",
        name: "User_services",
        component: User_services,
        meta: { title: 'Mes Services', authRequired: 'true' }
    },
    {
        path: "/mon-profil",
        name: "User_profil",
        component: User_profil,
        meta: { title: 'Mon profil', authRequired: 'true' }
    },
];

const router = createRouter({
    history: createWebHistory(),
    //mode: 'hash',
    routes,
});

router.afterEach((to, from, next) => {
    document.title = to.meta.title + ' | YaJobIci'

    if (to.name === 'Login' && localStorage.getItem("authUser")) {
        router.push({
            name: 'dashboard'
        })
    } else if (to.meta.authRequired && !localStorage.getItem("authUser")) {
        router.push({
            name: 'Login',
            query: {
                to: to.name
            }
        })
    }

    return next()
});

export default router;
