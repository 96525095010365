<template>
    <div class="Offre">
      <!-- <Navbar title="Yajobici" /> -->
  
      <div class=" p-0">
        <div class="">
          <div class="main-body">

            <div class="row p-0 m-0">
                <div class="col-lg-3 l-bg-orange-dark"></div>

                <div class="col-lg-9 p-5">
                    <div class="delete p-4">
                        <p style="font-size: 45px;">Comment supprimer son compte ?</p><br>

                        <ul>
                            <li>Allez sur la page d'accueil</li>
                            <li>Ensuite cliquez sur le boutton "Connexion" situé en haut à droite de la barre de navigation </li>
                            <li>Vous serez redirigé sur la page de Connexion(Remplissez le formulaire),</li>
                            <li>Une fois connecté, cliquez sur "Mon espace" en haut à droite de la barre de navigation,</li>
                            <li>Cliquez sur le boutton "Mes paramètres" dans la barre de navigation à gauche,</li>
                            <li>Enfin, cliquez sur "Supprimer mon compte" et suivez les différentes étapes.</li>
                        </ul>

                        <router-link to="/connexion">
                            <button class="btn" style=" color: white;border-radius: 5px !important; border: 2px solid #F7A51B !important; background-color: #F7A51B !important;">
                                Commencer le processus
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>

            

          </div>
          <!-- end  main body -->
        </div>
        <!-- end  container -->
  
        
  
        
      </div>
      <!-- end  container-fluid -->
    </div>
    <!-- end  div.offre -->
  </template>
  
  <script>
  export default {
    name: "DeleteAccount",
    components: {},
  };
  </script>
  
  <style scoped>

.Offre {
  height: 100%;
  min-height: 100vh;
}
.l-bg-orange-dark {
    /* background: linear-gradient(to right, #61C7F2, #F7A51B) !important; */
    background: linear-gradient(to right, #F7A51B, #61C7F2) !important;
    color: #fff;
}
.row{
    height: 100%;
  min-height: 100vh;
}

.delete ul>li{
    font-size: 20px !important;
}

  </style>
  