<template>
  <div class="Recruteur bg-white">
    <Navbar title="Yajobici" />
    <div>
      <!-- Header End -->
      <div
        class="container-fluid page-header mb-5"
        style="background: #61c7f2; margin-top: 75px !important"
      >
        <div class="container-fluid pb-1">
          <h1 class="pt-2 text-white mb-3 animated slideInDown">
            Enrégistrement
          </h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" style="color: #f7a51b !important">
                Accueil
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                Inscription
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header End -->
    </div>
    <div class="container bg-white p-0">
      <div class="row">
        <div class="col-md-5">
          <img
            class="img-fluid"
            src="../../assets/img/welcome.png"
            width="500"
          />
        </div>

        <div class="col-md-7">
          <div class="p-4">
            <form v-if="accountIsCreated" @submit.prevent="verifyUserOpt">
              <br />
              <div class="card p-4 py-5">
                <!-- //otp form -->
                <div
                  class="d-flex justify-content-center align-items-center container"
                >
                  <center class="mt-5">
                    <h5 class="m-0" style="color: #61c7f2 !important">
                      Vérification
                    </h5>
                    <br />
                    <span
                      ><b>Veuillez entrer ce code: {{ opt }}</b></span
                    >
                    <div class="row mt-3">
                      <div class="col-md-1"></div>
                      <div class="col-md-8">
                        <input
                          name="phoneNumber"
                          type="text"
                          class="form-control form-control-md"
                          id="phoneNumber"
                          v-model="phone"
                          required
                          readonly
                        />
                      </div>
                      <div class="col-md-3"></div>
                    </div>
                    <div class="row mt-4 mb-4">
                      <div class="col-md-8">
                        <div class="container text-center">
                          <VOtpInput
                            ref="otpInput"
                            input-classes="otp-input"
                            separator="-"
                            :num-inputs="4"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-change="handleOnChange"
                            @on-complete="handleOnComplete"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mt-1">
                          <button
                            class="btn btn-sm btn-danger w-100"
                            @click.prevent="clearInput()"
                          >
                            Effacer
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                          <BaseTimer
                            :canStartTimer="accountIsCreated"
                            :user-phone="phone"
                          />
                        </div>
                        <div class="col-md-1"></div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <button
                        v-if="loading"
                        disabled
                        class="btn btn-sm btn-dark w-100"
                        style="background: #c3c3c3; color: #ffffff"
                        type="submit"
                      >
                        Opération en cours ...
                      </button>
                      <button
                        v-else
                        class="btn btn-sm btn-warning w-100"
                        style="background: #f7a51b; color: #ffffff"
                        type="submit"
                      >
                        Valider
                      </button>
                    </div>
                    <!-- <div class="text-center mt-5">
                                        <span class=""> renvoyer le code</span>
                                    </div> -->
                  </center>
                </div>
                <!-- //otp form -->
              </div>
            </form>
            <form
              v-else
              class="default-form row pt-3"
              @submit.prevent="handleRegister"
            >
              <div class="card p-4">
                <div class="row mb-3">
                  <div class="col-6 mb-3">
                    <div class="form-group">
                      <label for="typeForm" class="form-label">
                        <small><b>Type d'acteur</b></small>
                      </label>
                      <select
                        class="form-control form-control-md"
                        v-model="typeForm"
                        required
                      >
                        <option value="Recruteur">Recruteur</option>
                        <option value="Prestataire">Prestataire</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 mb-3">
                    <div class="form-group">
                      <label for="type" class="form-label">
                        <small v-if="typeForm === 'Recruteur'"
                          ><b>Type de recruteur</b></small
                        >
                        <small v-if="typeForm === 'Prestataire'"
                          ><b>Type de prestataire</b></small
                        >
                      </label>
                      <select
                        class="form-control form-control-md"
                        v-model="type"
                        required
                      >
                        <option value="" selected>
                          --- Selectionnez une valeur ---
                        </option>
                        <option value="Entreprise">Entreprise</option>
                        <option value="Particulier">Particulier</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6 form-group mb-3">
                    <label for="nomRecruteur"
                      ><small><b>Nom</b></small></label
                    >
                    <input
                      name="nomRecruteur"
                      type="text"
                      class="form-control form-control-md"
                      id="nomRecruteur"
                      v-model="nom"
                      required
                    />
                    <small v-if="type === 'Entreprise'"
                      ><i
                        >&nbsp;&nbsp;Nom du représentant de l'entréprise</i
                      ></small
                    >
                  </div>
                  <div class="col-md-6 form-group mb-3">
                    <label for="prenomRecruteur"
                      ><small><b>Prénoms</b></small></label
                    >
                    <input
                      name="prenomRecruteur"
                      type="text"
                      class="form-control"
                      id="prenomRecruteur"
                      v-model="prenoms"
                      required
                    />
                    <small v-if="type === 'Entreprise'"
                      ><i
                        >&nbsp;&nbsp;Prénoms du représentant de l'entréprise</i
                      ></small
                    >
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="phoneNumber"
                      ><small><b>Numero de Téléphone</b></small></label
                    >
                    <input
                      name="phoneNumber"
                      type="text"
                      class="form-control form-control-md"
                      id="phoneNumber"
                      v-model="phone"
                      required
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="emailRecruteur"
                      ><small><b>Email</b></small></label
                    >
                    <input
                      name="emailRecruteur"
                      type="email"
                      class="form-control"
                      id="prenomRecruteur"
                      v-model="email"
                      required
                    />
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="niveauEtude"
                      ><small><b>Sexe</b></small></label
                    >
                    <select
                      v-model="genre"
                      class="form-control form-control-md"
                      name="niveauEtude"
                      id="niveauEtude"
                      required
                    >
                      <option value="" selected>
                        --- Selectionnez une valeur ---
                      </option>
                      <option value="Homme">Homme</option>
                      <option value="Femme">Femme</option>
                    </select>
                    <small v-if="type === 'Entreprise'"
                      ><i
                        >&nbsp;&nbsp;Genre du représentant de l'entréprise</i
                      ></small
                    >
                  </div>
                  <div class="col-md-6 mb-3" v-if="type != 'Entreprise'">
                    <label for="niveauEtude"
                      ><small><b>Niveau d'étude</b></small></label
                    >
                    <select
                      v-model="niveau"
                      class="form-control form-control-md"
                      name="niveauEtude"
                      id="niveauEtude"
                      required
                    >
                      <option value="" selected>
                        --- Selectionnez une valeur ---
                      </option>
                      <option value="Aucun">Aucun</option>
                      <option value="Secondaire">Secondaire</option>
                      <option value="Supérieur">Supérieur</option>
                      <option value="Elémentaire">Elémentaire</option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="adress"
                      ><small><b>Adresse</b></small></label
                    >
                    <GMapAutocomplete
                      placeholder="Ex: Abobo, belle ville"
                      @place_changed="setPlace"
                      class="form-control form-control-md"
                      v-model="addresse"
                      required
                    >
                    </GMapAutocomplete>
                  </div>
                  <!-- <div class="col-md-6 mb-3">
                                    <label for="localisation"><small><b>Localisation</b></small></label>
                                    <input 
                                        name="localisation" 
                                        type="text" 
                                        class="form-control form-control-md" 
                                        id="localisation"
                                        v-model="localisation"
                                        readonly
                                    >
                                </div> -->
                  <div class="col-md-6 mb-3">
                    <label for="photoRecruteur" class="form-label"
                      ><small><b>Photo</b></small></label
                    >
                    <input
                      ref="file"
                      accept=".jpg,.jpeg,.png"
                      @change="selectImage"
                      type="file"
                      class="form-control"
                      id="photoRecruteur"
                      name="photoRecruteur"
                      required
                    />
                    <small v-if="type === 'Entreprise'"
                      ><i
                        >&nbsp;&nbsp;Photo du représentant de l'entréprise</i
                      ></small
                    >
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="password" class="form-label"
                      ><small><b>Mot de passe</b></small></label
                    >
                    <input
                      v-model="password"
                      type="password"
                      class="form-control"
                      id="password"
                      placeholder="xxxxxxxxxxxxxx"
                      required
                    />
                  </div>

                  <div
                    class="col-md-6 mb-3"
                    v-if="type === 'Entreprise' && typeForm === 'Prestataire'"
                  >
                    <label for="nomEntreprise"
                      ><small><b>Nom de l'entréprise</b></small></label
                    >
                    <input
                      v-model="nom_entreprise"
                      required
                      name="nomEntreprise"
                      type="text"
                      class="form-control form-control-md"
                      id="nomEntreprise"
                    />
                  </div>

                  <!-- on doit prendre l'id de la sous categories -->
                  <div class="col-md-6 mb-3" v-if="typeForm === 'Prestataire'">
                    <label for="activiteId"
                      ><small><b>Activité</b></small></label
                    >
                    <select
                      v-model="activite_id"
                      class="form-control form-control-md"
                      name="activiteId"
                      id="activiteId"
                      required
                    >
                      <option value="" disabled selected>
                        --- Selectionnez une valeur ---
                      </option>
                      <optgroup
                        v-for="cat in categories"
                        :key="cat.id"
                        :label="cat.libelle"
                      >
                        <option
                          v-for="subcat in cat.children"
                          :key="subcat.id"
                          :value="subcat.id"
                        >
                          {{ subcat.libelle }}
                        </option>
                      </optgroup>
                      <!-- <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{ cat.libelle }}</option>  -->
                    </select>
                  </div>
                  <!-- on doit prendre l'id de la sous categories -->
                </div>

                <div class="col-md-12 text-center mb-3" v-if="previewImage">
                  <div class="row">
                    <div class="col-md-12">
                      <span>Aperçu de la photo</span>
                      <br />
                      <img
                        class="preview img-circle my-3"
                        :src="previewImage"
                        style="width: 300px !important"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <button
                    v-if="loading"
                    disabled
                    class="btn btn-dark w-100"
                    style="background: #c3c3c3; color: #ffffff"
                    type="submit"
                  >
                    Opération en cours ...
                  </button>
                  <button
                    v-else
                    class="btn btn-warning w-100"
                    style="background: #f7a51b; color: #ffffff"
                    type="submit"
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <Map/> -->
    <!-- Footer Start -->
    <Footer />
    <!-- Footer End -->
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import Navbar from "../../components/Navbar.vue";
import Swal from "sweetalert2";
import VOtpInput from "vue3-otp-input";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import BaseTimer from "../../components/BaseTimer.vue";
export default {
  name: "Register",
  components: {
    Navbar,
    Footer,
    VOtpInput,
    BaseTimer,
  },
  data() {
    return {
      type: "",
      nom: "",
      prenoms: "",
      phone: "",
      email: "",
      addresse: "",
      niveau: "",
      genre: "",
      currentImage: undefined,
      previewImage: undefined,
      password: null,
      nom_entreprise: null,
      activite_id: "",
      typeForm: "Recruteur",
      localisation: null,
      loading: false,
      accountIsCreated: false,
      categories: [],
      otp_code: null,
      opt: null,
    };
  },
  mounted() {
    this.categoriesList();
  },
  setup() {
    const otpInput = ref(null);
    const toast = useToast();
    const clearInput = () => {
      otpInput.value.clearInput();
    };

    return { clearInput, otpInput, toast };
  },
  methods: {
    setPlace(event) {
      var lat = event.geometry.location.lat();
      var lng = event.geometry.location.lng();

      //Attribution des valeurs de localidsation et adresse
      this.localisation = lng + ";" + lat;
      this.addresse = event.name;
      //console.log("event ",event.geometry.viewport);
    },
    handleOnComplete(value) {
      this.otp_code = value;
    },
    selectImage(e) {
      this.currentImage = e.target.files[0];
      //this.currentImage = this.$refs.file.files.item(0);
      let previewImage = URL.createObjectURL(e.target.files[0]);
      this.previewImage = previewImage;
      this.progress = 0;
      this.message = "";
    },

    async verifyUserOpt() {
      this.loading = true;
      var data = {
        code_otp: this.otp_code,
        phone: "225" + this.phone,
        password: this.password,
      };

      await this.$store.dispatch("auth/verirfyotp", data).then(
        (response) => {
          this.loading = false;
          if (response.success) {
            let timerInterval;
            Swal.fire({
              title: "Authentification en cours",
              icon: "success",
              html:
                response.message +
                " redirection vers votre espace personnel dans <b></b> milliseconds.",
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                //console.log('I was closed by the timer')
              }
              this.toast.success(response.message, {
                position: "top-right",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.$router.push({ name: "dashboard" });
            });
          }
        },
        (error) => {
          this.loading = false;
          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message +
                  " " +
                  JSON.stringify(error.response.data.data)) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },
    async handleRegister() {
      this.message = "";
      this.loading = true;

      var data = {
        type: this.type,
        nom: this.nom,
        prenoms: this.prenoms,
        phone: "225" + this.phone,
        email: this.email,
        addresse: this.addresse,
        adress: this.addresse,
        niveau: this.niveau,
        genre: this.genre,
        photo: this.currentImage,
        password: this.password,
        nom_entreprise: this.nom_entreprise,
        activite_id: parseInt(this.activite_id),
        localisation: this.localisation,
        typeForm: this.typeForm,
      };

      await this.$store.dispatch("register/register", data).then(
        (response) => {
          this.loading = false;
          if (response.success) {
            //Affichage du formulaire opt
            //console.log("OTP ",response.data.code_otp);
            this.opt = response.data.code_otp;
            this.accountIsCreated = true;
            this.message = response.message;

            this.toast.success(response.message, {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;

          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message +
                  " " +
                  JSON.stringify(error.response.data.data)) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },

    async categoriesList() {
      await this.$store.dispatch("staticdata/fetchCatAndSubCat").then(
        (data) => {
          this.categories = data.data;
        },
        (error) => {
          console.log("error--- ", error);
        }
      );
    },
  },
};
</script>

<style scoped>
.preview {
  max-height: 400px;
}
.input_otp {
  margin-left: 25% !important;
}
</style>
