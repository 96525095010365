<template>
  <div v-if="canStartTimer" class="text-center">
    <br />

    <p v-if="formattedTimeLeft == '0:00'" @click.prevent="resendOtp(userPhone)">
      <u style="color: #61c7f2; cursor: pointer"> Renvoyer l'OTP</u>
    </p>
    <p v-else>
      <small>
        Renvoyer l'OTP dans
        <br />
        <span
          class=""
          style="color: #61c7f2; font-weight: bold; font-size: 23px"
          >{{ formattedTimeLeft }}</span
        >
        <br />
        Second(s)
      </small>
    </p>
  </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";
const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 50;
const ALERT_THRESHOLD = 20;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};

var TIME_LIMIT = 10; //60; //Time en second

export default {
  data() {
    return {
      timePassed: 0,
      timerInterval: null,
      phone: "",
      message: "",
    };
  },
  setup() {
    const otpInput = ref(null);
    const toast = useToast();
    const clearInput = () => {
      otpInput.value.clearInput();
    };

    return { clearInput, otpInput, toast };
  },
  props: {
    canStartTimer: Boolean,
    userPhone: String,
  },
  computed: {
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
  },

  mounted() {
    if (this.canStartTimer) {
      this.startTimer();
    }
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },

    async resendOtp(phonenum) {
      var data = {
        phone: phonenum,
      };
      await this.$store.dispatch("auth/resendOtpRequest", data).then(
        (resp) => {
          this.timePassed = 0;
          this.timerInterval = null;
          this.startTimer();
          this.toast.success(resp.message, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.isLoading = false;

          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },
  },
};
</script>
