<template>
    <div class="sidebar">
        <div class="sidebar-header">
            <h3 class="brand">
                <span class="ti-unlink"></span> 
                <span class="text-center">
                    <router-link to="/">
                    <img 
                        style="border-radius: 10px !important; width: 90% !important;" 
                        class="img-fluid rounded mt-4 mb-3" 
                        src="../assets/img/yajobici.png">
                    </router-link>
                </span>
            </h3> 
            <label for="sidebar-toggle" class="text-center">
              <font-awesome-icon style="font-size: 17px !important;color: #F7A51B;" icon="list"/>
            </label>
        </div>
        
        <div class="sidebar-menu">
            <ul>
                <li :class="currentRouteName == 'dashboard' ? 'active' : ''">
                    <router-link to="/dashboard-espace-utilisateur">
                        &nbsp;&nbsp;&nbsp;
                          <font-awesome-icon style="font-size: 17px !important" icon="house"/>
                          <span style="font-size: 15px !important;">Accueil</span>
                    </router-link>
                </li>
                <li v-if="typeUser != '' && typeUser == 'prestataire'" :class="currentRouteName == 'User_services' ? 'active' : ''">
                    <router-link to="/mes-services">
                      &nbsp;&nbsp;&nbsp;
                        <font-awesome-icon style="font-size: 17px !important" icon="folder"/>
                        <span style="font-size: 15px !important;">
                          Mes services
                        </span>
                    </router-link>
                </li>
                <li :class="currentRouteName == 'User_profil' ? 'active' : ''">
                    <router-link to="mon-profil">
                      &nbsp;&nbsp;&nbsp;
                        <font-awesome-icon style="font-size: 17px !important" icon="cog"/>
                        <span style="font-size: 15px !important;">
                          Mes paramètres
                        </span>
                    </router-link>
                </li>
                <li  @click.prevent="logOut" class="btn-deco" style="position: absolute; bottom: 0px !important; cursor: pointer;">
                    &nbsp;&nbsp;&nbsp; 
                    <font-awesome-icon icon="sign-out-alt" />
                    <span style="font-size: 15px !important;">Se déconnecter</span>
                </li>
                
            </ul>
        </div>
    </div>
</template>
  
<script>
    export default {
        name: 'UserSideBar',
        props: {
            typeUser: String
        },
        data() {
            return {
                currentRouteName: this.$route.name,
            };
        },
        methods: {
            logOut() {
                this.$store.dispatch('auth/logout');
                window.location.reload();
            }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
  
  :root {
      --main-color: #027581;
      --color-dark: #1D2231;
      --text-grey: #8390A2;
  }
  
  * {
      font-family: 'Poppins', sans-serif;
      margin: 0;
      padding: 0;
      text-decoration: none;
      list-style-type: none;
      box-sizing: border-box;
  }
  
  #sidebar-toggle {
      display: none;
  }
  
  .sidebar {
      height: 100%;
      width: 200px;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 100;
      background: #61C7F2 !important;
      color: #fff;
      overflow-y: auto;
      transition: width 500ms;
  }
  .active a
  {
    color: #F7A51B !important;
    font-weight: bold !important;
  }
  
  .btn-deco:hover{
    padding: 5px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #F7A51B;
    background-color: #ffffff;
  }
  .sidebar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 0rem 1rem;
  }
  
  .sidebar-menu {
      padding: 1rem;
  }
  
  .sidebar li {
      margin-bottom: 1.5rem;
  }
  
  .sidebar a {
      color: #fff;
      font-size: .8rem;
  }
  
  .sidebar a span:last-child {
      padding-left: .6rem;
  }
  
  #sidebar-toggle:checked ~ .sidebar .sidebar-header h3 span,
  #sidebar-toggle:checked ~ .sidebar li span:last-child {
      display: none;
  }
  
  #sidebar-toggle:checked ~ .sidebar .sidebar-header,
  #sidebar-toggle:checked ~ .sidebar li {
      display: flex;
      justify-content: center;
  }
  
  #sidebar-toggle:checked ~ .main-content {
      margin-left: 60px;
  }
  
  #sidebar-toggle:checked ~ .main-content header {
      left: 60px;
      width: calc(100% - 60px);
  }
  
  .main-content {
      position: relative;
      margin-left: 200px;
      transition: margin-left 500ms;
  }
  
  header {
      position: fixed;
      left: 200px;
      top: 0;
      z-index: 100;
      width: calc(100% - 200px);
      background: #fff;
      height: 60px;
      padding: 0rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      transition: left 500ms;
  }

  main {
      margin-top: 60px;
      background: #f1f5f9;
      min-height: 90vh;
      padding: 1rem 3rem;
  }
  
  .dash-title {
      color: var(--color-dark);
      margin-bottom: 1rem;
  }
  
  .dash-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 3rem;
  }
  
  .card-single {
      background: #fff;
      border-radius: 7px;
      box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  }
  
  .card-body {
      padding: 1.3rem 1rem;
      display: flex;
      align-items: center;
  }
  
  .card-body span {
      font-size: 1.5rem;
      color: #777;
      padding-right: 1.4rem;
  }
  
  .card-body h5 {
      color: var(--text-grey);
      font-size: 1rem;
  }
  
  .card-body h4 {
      color: var(--color-dark);
      font-size: 1.1rem;
      margin-top: .2rem;
  }
  
  .card-footer {
      padding: .2rem 1rem;
      background: #f9fafc;
  }
  
  .card-footer a {
      color: var(--main-color);
  }
  
  .recent {
      margin-top: 3rem;
      margin-bottom: 3rem;
  }
  
  .activity-grid {
      display: grid;
      grid-template-columns: 75% 25%;
      grid-column-gap: 1.5rem;
  }
  
  .activity-card,
  .summary-card,
  .bday-card {
      background: #fff;
      border-radius: 7px;
  }
  
  .activity-card h3 {
      color: var(--text-grey);
      margin: 1rem;
  }
  
  .activity-card table {
      width: 100%;
      border-collapse: collapse;
  }
  
  .activity-card thead {
      background: #efefef;
      text-align: left;
  }
  
  th, td {
      font-size: .9rem;
      padding: 1rem 1rem;
      color: var(--color-dark);
  }
  
  td {
      font-size: .8rem;
  }
  
  tbody tr:nth-child(even) {
      background: #f9fafc;
  }
  
  .badge {
      padding: .2rem 1rem;
      border-radius: 20px;
      font-weight: 700;
      font-size: .7rem;
  }
  
  .badge.success {
      background: #DEF7EC;
      color: var(--main-color);
  }
  
  .badge.warning {
      background: #F0F6B2;
      color: orange;
  }
  
  .td-team {
      display: flex;
      align-items: center;
  }
  
  .img-1,
  .img-2,
  .img-3 {
      height: 38px;
      width: 38px;
      border-radius: 50%;
      margin-left: -15px;
      border: 3px solid #efefef;
      background-size: cover;
      background-repeat: no-repeat;
  }

  .summary-card {
      margin-bottom: 1.5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
  }
  
  .summary-single {
      padding: .5rem 1rem;
      display: flex;
      align-items: center;
  }
  
  .summary-single span {
      font-size: 1.5rem;
      color: #777;
      padding-right: 1rem;
  }
  
  .summary-single h5 {
      color: var(--main-color);
      font-size: 1.1rem;
      margin-bottom: 0rem !important;
  }
  
  .summary-single small {
      font-weight: 700;
      color: var(--text-grey);
  }
  
  .bday-flex {
      display: flex;
      align-items: center;
      margin-bottom: .3rem;
  }
  
  .bday-card {
      padding: 1rem;
  }

  
  .text-center {
      text-align: center;
  }
  
  .text-center button {
      background: var(--main-color);
      color: #fff;
      border: 1px solid var(--main-color);
      border-radius: 4px;
      padding: .4rem 1rem;
  }
  
  .table-responsive {
      overflow-x: auto;
  }
  
  @media only screen and (max-width: 1200px) {
      .sidebar {
          width: 60px;
          z-index: 150;
      }
  
      .sidebar .sidebar-header h3 span,
      .sidebar li span:last-child {
          display: none;
      }
  
      .sidebar .sidebar-header,
      .sidebar li {
          display: flex;
          justify-content: center;
      }
  
      .main-content {
          margin-left: 60px;
      }
  
      .main-content header {
          left: 60px;
          width: calc(100% - 60px);
      }
      
      #sidebar-toggle:checked ~ .sidebar {
          width: 240px;
      }
  
      #sidebar-toggle:checked ~ .sidebar .sidebar-header h3 span,
      #sidebar-toggle:checked ~ .sidebar li span:last-child {
          display: inline;
      }
  
      #sidebar-toggle:checked ~ .sidebar .sidebar-header {
          display: flex;
          justify-content: space-between;
      }
      
      #sidebar-toggle:checked ~ .sidebar li {
          display: block;
      }
  
      #sidebar-toggle:checked ~ .main-content {
          margin-left: 60px;
      }
  
      #sidebar-toggle:checked ~ .main-content header {
          left: 60px;
      }
  }
  
  @media only screen and (max-width: 860px) {
      .dash-cards {
          grid-template-columns: repeat(2,1fr);
      }
      
      .card-single {
          margin-bottom: 1rem;
      }
      
      .activity-grid {
          display: block;
      }
      
      .summary {
          margin-top: 1.5rem;
      }
  }
  
  @media only screen and (max-width: 600px) {
      .dash-cards {
          grid-template-columns: 100%;
      }
  }
  
  @media only screen and (max-width: 450px) {
      main {
          padding-left: 1rem;
          padding-right: 1rem;
      }
  }
  
  
  </style>
  