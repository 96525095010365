<template>
  <!-- <navbar/> -->

  <!-- écrit ici (début) -->

  <div class="bodi">
    <!-- Hero (Start) -->
    <div class="py-5 bg-hero" style="margin-bottom: 70px">
      <div class="container py-3">
        <div class="row justify-content-start">
          <div class="col-lg-8 text-center text-lg-start">
            <h1 class="display-5 text-light">A propos de nous</h1>
            <div class="pt-2">
              <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
                <ol class="breadcrumb" style="color: #f7a51b">
                  <li class="breadcrumb-item">
                    <router-link to="/" style="color: #f7a51b"
                      >Accueil</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    A propos
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero (End) -->

    <div class="container">
      <!-- Definir yajobici (start) -->
      <div class="row mb-4 container">
        <div class="col-lg-4 mb-3">
          <img
            src="../../assets/yajobici.png"
            class="img-thumbnail p-5"
            alt=""
            style="background-color: transparent !important"
          />
        </div>
        <div class="col-lg-8">
          <h2 class="mb-3" style="color: #283779 !important">
            Qu’est-ce que yajobici ?
          </h2>
          <p class="mb-5">
            <span style="color: #f7a51b">YAJOBICI</span> est une plateforme de
            mise en relation au moyen d’internet entre des prestataires et
            recruteurs (entreprises ou particuliers) qui souhaitent attirer de
            nouveaux clients grâce à internet qui souhaitent attirer de nouveaux
            clients et des recruteurs qui recherchent des prestataires
            compétents, pour un service Sur notre plateforme, vous trouverez
            diverses catégories de service, de votre bien être personnel en
            passant par l’entretien de votre maison jusqu’au dépannage. Nous
            rassemblons une communauté de plusieurs prestataires qualifiés à
            votre service au quotidien.
          </p>
        </div>
      </div>
      <br />
      <!-- Definir yajobici (end) -->

      <!-- comment ça marche (start) -->
      <div class="Title mt-5 mb-5">
        <div class="parent block1">Alors Comment ça marche ?</div>
        <div class="parent block2 block2_1">Alors Comment ça marche ?</div>
      </div>
      <div class="row mb-5 container">
        <div class="col-lg-6">
          <div
            class="card prestat__card"
            style="
              border-bottom: 4px solid #f7a51b !important;
              min-height: 450px !important;
            "
          >
            <h4 class="mb-3 mt-3">
              Vous êtes un prestataire
              <span style="color: #f7a51b">particulier ou entreprise ?</span>
            </h4>
            <div class="mb-3">
              <div class="dive">
                <ol>
                  <li class="liste_type">
                    Inscrivez-vous sur l’application YAJOBICI en choisissant le
                    ou les services que vous proposez;
                  </li>
                  <li class="liste_type">
                    Renseignez votre profil en y ajoutant une photo (logo, photo
                    d’identité…);
                  </li>
                  <li class="liste_type">
                    Renseignez le nom de l’entreprise et l’attestation
                    d’existence (DFE);
                  </li>
                  <li class="liste_type">
                    Choisissez votre abonnement « normal (500F/mois) ou
                    (premium(1000F/mois) pour que vos différents prestataires
                    puissent apparaitre chez le recruteur et/ou peut bénéficier
                    d’une plus large visibilité sur la plateforme; votre profil
                    sera ensuite validé par nos services en moins de 24h;
                  </li>
                  <li class="liste_type">
                    Pour vérifier vos commandes; cliquez sur le bouton «en
                    cours»
                  </li>
                  <li class="liste_type">Vous êtes rémunérés par le client.</li>
                  <li class="liste_type">
                    Pour vérifier vos commandes; cliquez sur le bouton «en
                    cours»
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="card recrut__card"
            style="
              border-bottom: 4px solid #61c7f2 !important;
              min-height: 450px !important;
            "
          >
            <h4 class="mb-3 mt-3">
              Vous êtes un recruteur
              <span style="color: #61c7f2">particulier ou entreprise?</span>
            </h4>
            <div class="mb-3 mt-3">
              <div class="dive">
                <div class="box">
                  <ol>
                    <li class="liste_type">
                      Inscrivez-vous sur l’application YAJOBICI en tant que
                      particulier ou entreprise
                    </li>
                    <li class="liste_type">
                      Choisissez-le(s) service(s) qui correspond (ent) ainsi que
                      le prestataire dans la liste
                    </li>
                    <li class="liste_type">Commandez ou réservez</li>
                    <li class="liste_type">
                      Réglez le prestataire à la fin de son service
                    </li>
                    <li class="liste_type">
                      Notez votre prestataire et laissez un commentaire
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <p>
              <strong>NB :</strong> Pour bénéficier de ressources
              professionnelles et expérimentées, nous vous conseillons de faire
              le choix du pass premium qui vous coutera
              <strong>500 FCFA/mois</strong>.
            </p>
          </div>
        </div>
      </div>
      <!-- comment ça marche (end) -->

      <!-- foire aux questions (start) -->
      <div class="Title mt-5 mb-5">
        <div class="parent block1">Foire aux questions</div>
        <div class="parent block2 block2_2">Foire aux questions</div>
      </div>
      <br />

      <div class="row">
        <div class="col-lg-4">
          <img src="../../assets/img/faq.png" class="img-fluid" width="300" />
        </div>

        <div class="col-lg-8">
          <div class="accordion mt-5" id="accordionExample">
            <div class="accordion-item" style="">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Comment télécharger l’application ?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  L’application est téléchargeable sur App Store et sur Google
                  Play.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Puis- je utiliser YAJOBICI à l’étranger ?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Pas disponible à l’étranger en ce moment, uniquement en côte
                  d’ivoire.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Comment créer un compte ?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Renseignez votre email, remplissez la fiche d'inscription et
                  créez votre mot de passe.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Je suis professionnel, l’inscription est - elle gratuite et
                  obligatoire ?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  L’inscription sur YAJOBICI est totalement gratuite ! Elle est
                  obligatoire si vous souhaitez profiter d’une mise en relation
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Peut-on réserver une prestation pour quelqu’un d’autre ?
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Oui on peut réserver une prestation pour un proche et payer
                  auprès du prestataire directement ou via un moyen de paiement
                  mobile (orange, moov, mtn ou wave).
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Peut-on créer un profil pour quelqu’un ?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Chaque personne utilisant YAJOBICI doit avoir son propre
                  profil ! Chaque profil crée sur le site doit être fiable et
                  nous vérifions certaines informations pour des questions de
                  sécurité et de confiance entre les membres.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSeven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Je suis prestataire, quand et comment recevoir mon paiement ?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Le paiement se fait directement avec le client (entreprise ou
                  particulier) après la prestation.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingHeight">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseHeight"
                  aria-expanded="false"
                  aria-controls="collapseHeight"
                >
                  Peut-on choisir son prestataire soi-même ?
                </button>
              </h2>
              <div
                id="collapseHeight"
                class="accordion-collapse collapse"
                aria-labelledby="headingHeight"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Oui lorsque vous commandez ou réservez, vous choisissez votre
                  prestataire vousmême.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingNine">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Pourquoi choisir yajobici ?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Chez <b>YAJOBICI</b> nous travaillons avec des prestataire(s)
                  dévoué(es) à moindre couts et en facilitant votre recherche de
                  personnel. Avec YAJOBICI trouvez vos prestataires en quelques
                  cliques.
                </div>
              </div>
            </div>
          </div>
        </div><br><br>
      </div>
      <!-- foire aux questions (end) -->
    </div>
  </div>
  <!-- écrit ici (fin) -->
  <Footer />
</template>

<script>
// import Navbar from "../../components/Navbar.vue";
// import Footer from "../../components/Footer.vue";

export default {
  name: "DetailService",
  components: {
    // Navbar,
    // Footer,
  },
};
</script>

<style scoped>
/********** style CSS **********/

.bodi {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #54595f;
  background-color: #fbfdff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn {
  font-weight: 700;
  transition: 0.1s;
  background-color: #f7a51b; /* Green */
  border: none;
  color: rgb(255, 255, 255);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.btn:hover {
  -webkit-box-shadow: 0 8px 6px -6px #555555;
  -moz-box-shadow: 0 8px 6px -6px #555555;
  box-shadow: 0 8px 6px -6px #555555;
}

.btn.btn-secondary {
  color: #a73535;
}

.bg-hero {
  background-size: contain;
  background-color: #60e4ff;
}
.bg-hero2 {
  background-size: contain;
  background-color: #60e4ff;
}

@media (max-width: 991.98px) {
  .bg-hero {
    background-size: cover;
  }
}

.about-start,
.about-end {
  background-size: contain;
  background-color: #60e4ff;
}

@media (min-width: 992px) {
  .about-start {
    position: relative;
    margin-right: -90px;
    z-index: 1;
  }

  .about-end {
    position: relative;
    margin-left: -90px;
    z-index: 1;
  }
}

.img-border {
  position: relative;
  height: 100%;
  min-height: 400px;
}

.img-border::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 3rem;
  bottom: 3rem;
  border: 5px solid #f7a51b;
  border-radius: 6px;
}

.img-border img {
  position: absolute;
  top: 3rem;
  left: 3rem;
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  object-fit: cover;
  border-radius: 6px;
}
/* style about prestataire */

/*** Img Border ***/
.img-bord {
  position: relative;
  height: 100%;
  min-height: 400px;
}

.img-bord::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 3rem;
  bottom: 3rem;
  border: 5px solid #ffffff;
  border-radius: 6px;
}

.img-bord img {
  position: absolute;
  top: 3rem;
  left: 3rem;
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  object-fit: cover;
  border-radius: 6px;
}

/* style about recruteur */

@-webkit-keyframes typing {
  from {
    width: 0;
  }
}
@-webkit-keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}

h2.titre {
  font-weight: bold 100%;
  width: 16.5em; /* fallback */
  width: 30ch; /* # of chars */
  white-space: nowrap;
  overflow: hidden;
  -webkit-animation: typing 10s steps(30, end),
    /* # of steps = # of chars */ underscore 0.5s step-end infinite alternate;
}

div .titlle {
  line-height: -0px !important;
  color: #fff;
  padding: 50px;
  font-weight: 900;
}

.card {
  margin-top: 50px !important;
  padding: 15px !important;
  border: none !important;
  background-color: #dddddd75 !important;
}

div.parent {
  width: 100%;
  display: block;
  /* font-size:50px; */
  text-transform: uppercase;
  text-align: center;
}

.block1 {
  font-size: 60px;
  z-index: 1;
  color: #1b72a111;
  font-weight: 700 !important;
}

.block2 {
  font-size: 25px;
  color: #283779 !important;
  z-index: 0;
  font-weight: 700 !important;
  margin-top: -60px;
}

/* width <= 412px */
@media screen and (max-width: 412px) {
  .block2_2 {
    margin-top: -110px !important;
  }
  .block2_1 {
    margin-top: -240px !important;
  }
}

/* width >= 810px */
/* @media screen and (min-width: 810px) {
  .block2_1 {
    margin-top: -100px !important;
  }
} */

.accordion-body {
  color: #f7a61bc0 !important;
}
.accordion-item {
  background-color: white !important;
  border-bottom: 1px solid #61c7f2 !important;
  margin-bottom: 15px !important;
  border-radius: 15px !important;
}
.accordion-button {
  background-color: white !important;
  color: #283779cf !important;
}

.dive {
  display: grid;
  place-items: center;
  /* padding: 4vmin; */
}

ol {
  list-style: none;
  counter-reset: op-order-list;
}

li.liste_type {
  counter-increment: op-order-list;
  font-size: 2.2vmin;
  transition: transform 1.2s ease;
}

li.liste_type::before {
  content: "";
  width: 2vmin;
  height: 2vmin;
  border-radius: 50% 50% 0% 50%;
  background: linear-gradient(135deg, #f7a51b 0%, #61c7f2 100%);
  color: #1d1e22;
  font-size: 3vmin;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  transition: border-radius 1s ease, background 2s ease;
}

li.liste_type:hover {
  border-radius: 4vmin;
  transform: translatex(6vmin);
  font-weight: 700;
}

li.liste_type:hover::before {
  background: linear-gradient(135deg, #f7a51b 0%, #fff 100%);
  padding-left: 1vmin;
  border-radius: 0% 50% 50% 50%;
  color: #1d1e22;
}

</style>
