<template>
    <nav class="navbar navbar-expand-lg bg-white navbar-light p-0 fixed-top mb-5" style="background: #FFFFFF !important;border-bottom: 1px solid #F7A51B;">
        <router-link to="/" class="navbar-brand d-flex align-items-center text-center py-0 px-4 px-lg-5">
            <img class="img-fluid" src="../assets/img/yajobici.png" width="160">
        </router-link>
        <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto p-4 p-lg-0">
                <router-link to="/" :class="currentRouteName == 'home' ? 'nav-item nav-link active mt-2' : 'nav-item nav-link mt-2'">Accueil</router-link>
                <router-link to="/nos-services" :class="currentRouteName == 'service' || currentRouteName == 'detailService' || currentRouteName == 'categorieService' || currentRouteName == 'detailActeur' ? 'nav-item nav-link active mt-2' : 'nav-item nav-link mt-2'">Nos services</router-link>
                <router-link v-if="!currentUser" to="/connexion" class="nav-item nav-link">
                    <button class="btn btn-sm" style="background: #61C7F2; height: 40px; color: #FFFFFF; border-radius: 8px;">
                        &nbsp;&nbsp;<font-awesome-icon icon="sign-in-alt" /> Connexion&nbsp;&nbsp;
                    </button>
                </router-link>
                <router-link v-if="!currentUser" to="/creation-de-compte" class="nav-item nav-link">
                    <button class="btn btn-sm " style="background: #61C7F2; height: 40px; color: #FFFFFF; border-radius: 8px;">
                        &nbsp;&nbsp;<font-awesome-icon icon="user-plus" /> Inscription&nbsp;&nbsp;&nbsp;
                    </button>
                </router-link>
                <router-link v-if="currentUser" to="/dashboard-espace-utilisateur" class="nav-item nav-link mt-2">
                    <font-awesome-icon icon="user"/>
                    &nbsp;
                    Mon espace
                </router-link>
                <div class="nav-item nav-link mt-1">
                    <button v-if="currentUser" @click.prevent="logOut" type="button" class="btn btn-sm btn-outline-warning w-100">
                        &nbsp;<font-awesome-icon icon="sign-out-alt" />&nbsp;
                    </button>
                </div>
            </div>
        </div> 
    </nav>
</template>
<script>
    import { useToast } from "vue-toastification";
    export default {
        name: 'NavBar',
        props: {
            isActive: Boolean
        },
        computed: {
            mcurrentUser() {
                return this.$store.state.auth.user;
            },
        },
        setup() {
            const toast = useToast();
            return { toast };
        },
        data() {
            return {
                currentUser: null,
                currentRouteName: this.$route.name,
                userPhone: null,
                userName: null,
                userEmail: null
            };
        },
        mounted() {
            const target_copy = Object.assign({}, JSON.parse(localStorage.getItem('authUser')));
            //console.log("DDD ", target_copy);
            if (target_copy.user) {
                this.currentUser = target_copy.user;
                this.userPhone   = target_copy.user.phone;
            }
            //console.log("AAA ", this.currentUser);
        },
        methods: {
            logOut() {
                this.$store.dispatch('auth/logout');
                window.location.reload();
            }
        }
    }
</script>