import { createStore } from "vuex";
import { auth } from "./auth.module";
import { register } from "./register.module";
import { staticdata } from "./staticdata.module";
import { user } from "./user.module";

const store = createStore({
    modules: {
        register,
        auth,
        staticdata,
        user
    },
});

export default store;