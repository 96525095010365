<template>
    <div>
        <input type="checkbox" id="sidebar-toggle">
        <UserSideBar
            :typeUser="userType"
        />
      
        <div class="main-content">
            <UserNavbar
                :data="userData"
            />
            <!-- contenu start -->
            <main>
                <section class="section" >
                    <div class="container-fluid" >
                        <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                            <div class="col-lg-8 mb-3">
                                <h3 class="h1 m-15px-b">Les services que vous aviez ajouté</h3>
                                <small v-if="userServices != null && userServices.length > 0">
                                    <span class="text-danger">
                                        Pour modifier vos services veuillez utiliser l'application mobile
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div v-if="userServices == null" class="row mt-3 text-center">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-8">
                                <span class="text-warning">
                                    Chargement en cours ...
                                </span>
                            </div>
                            <div class="col-lg-2"></div>
                        </div>
                        <div v-else class="row">
                            <div v-if="userServices.length == 0" class="col-lg-12">
                                <p class="text-center mt-5" style="color: #F7A51B !important;">
                                    Aucun service disponible! &nbsp;
                                    Veuillez ajouter vos services via l'application mobile :)
                                </p>
                            </div>
                            <div v-else v-for="d in userServices" :key="d.id" class="col-sm-6 col-lg-4 m-15px-tb">
                                <div class="media box-shadow-only-hover hover-top border-all-1 border-color-gray p-15px" style="border: 1px solid #F7A51B !important;border-radius: 5px !important;">
                                    <a class="overlay-link" href="#"></a>
                                    <div class="row">
                                        <div class="col-md-2 text-center">
                                            <div class="icon-50 theme-bg white-color border-radius-50 d-inline-block">
                                                <i class="number">YJI</i>
                                            </div>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="p-20px-l media-body">
                                                <small class="py-4">
                                                    <h6 class="m-5px-tb mb-2">{{ d.service.libelle }}</h6>
                                                </small>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        Prix min &nbsp;: &nbsp;<span class="badge badge-warning" style="background: #61C7F2 !important;border-radius: 5px !important;">{{ d.prix_min }} &nbsp;&nbsp;F CFA</span>
                                                    </div>
                                                    <div class="col-md-12">
                                                        Prix max : &nbsp;<span class="badge badge-warning" style="background: #F7A51B !important;border-radius: 5px !important;">{{ d.prix_max }} &nbsp;&nbsp; F CFA </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <!-- contenu end -->
          
        </div>
    </div>
  </template>
  
  <script>
  import UserNavbar from "../../components/UserNavbar.vue";
  import UserSideBar from "../../components/UserSideBar.vue";
  import { useToast } from "vue-toastification";
  export default {
    name: 'Dashboard',
    components: {
        UserNavbar,
        UserSideBar
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            userData: null,
            userServices: null,
            userType: "",
        };
    },
    mounted() {
        this.fetchUserInfos();
        this.fetchUserServices();
    },
    methods: {
        async fetchUserInfos() {
            var authData = Object.assign({}, JSON.parse(localStorage.getItem('authUser')));
            this.userType = authData.user.acteur;
            
            await this.$store.dispatch("user/retrieveUserInfo")
            .then(
                (response) => {
                    this.userData = response.data; 
                },
                (error) => {
                    console.log("retrieveUserInfo error--- ", error);
                }
            );
        },
        async fetchUserServices() {
            this.loading = true;
            await this.$store.dispatch("user/retrieveUserService")
            .then(
                (response) => {
                    this.loading = false;
                    this.userServices = response.data; 
                },
                (error) => {
                    this.loading = false;
                    console.log("retrieveUserService error--- ", error);
                }
            );
        }
    }
  }
  </script>
  
  <style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
  
    :root {
        --main-color: #027581;
        --color-dark: #1D2231;
        --text-grey: #8390A2;
    }
  
    * {
        font-family: 'Poppins', sans-serif;
        margin: 0;
        padding: 0;
        text-decoration: none;
        list-style-type: none;
        box-sizing: border-box;
    }
  
    .main-content {
        position: relative;
        margin-left: 200px;
        transition: margin-left 500ms;
    }
  
    main {
        margin-top: 60px;
        min-height: 90vh;
        padding: 1rem 3rem;
    }
  
    @media only screen and (max-width: 1200px) {
        .main-content {
            margin-left: 60px;
        }
    }
  
    @media only screen and (max-width: 450px) {
        main {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .p-15px {
        padding: 15px;
    }
    .border-color-gray {
        border-color: #F7A51B !important;
    }
    .border-all-1 {
        border: 1px solid #F7A51B !important;
    }
    .hover-top {
        position: relative;
        top: 0;
    }
    .m-15px-tb {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .overlay-link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 0;
    }
    .border-radius-50 {
        border-radius: 50%;
    }
    .icon-50 {
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 21px;
    }
    .white-color {
        color: #ffffff;
    }
    .theme-bg {
        background-color: #61C7F2;
    }
    .icon-50 i.number {
        font-style: normal;
    }
    .icon-50 i {
        line-height: 50px;
    }
    .p-20px-l {
        padding-left: 20px;
    }
    .p-10px-lr {
        padding-left: 10px;
        padding-right: 10px;
    }
    .p-0px-tb {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .border-radius-15 {
        border-radius: 15px;
    }
    .m-0px {
        margin: 0px;
    }
    .font-small {
        font-size: .75rem;
        line-height: 1rem;
    }

    .border-radius-50 {
        border-radius: 50%;
    }
    .icon-50 {
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 21px;
    }

    .box-shadow-only-hover:hover {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
    }
    .border-color-gray {
        border-color: #f2f3fa !important;
    }
    .border-all-1 {
        border: 1px solid;
    }
</style>
