<template>
  <div class="Details Services">
    <Navbar title="Yajobici" />
    <div class="container-fluid p-0">
      <div style="margin-top: 80px !important">
        <!-- Header End -->
        <div
          class="page-header mb-5"
          style="background-color: #61c7f2 !important"
        >
          <div class="container pt-3 pb-4">
            <h2 class="text-white mb-2 animated slideInDown">
              {{ subCategorieName }}
            </h2>
          </div>
        </div>
        <!-- Header End -->

        <div class="container">
          <!-- acteurs by domaines -->
          <div class="mt-5 mb-5">
            <!-- Category Start -->
            <div class="container-xxl py-5">
              <div class="container">
                <h1 class="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
                  Explorer nos prestataires
                </h1>
                <div v-if="isLoading" class="text-center">
                  <span style="color: #f7a51b !important">
                    Recuperation de la liste des prestataires ...
                  </span>
                </div>
                <div v-else class="row d-flex justify-content-center">
                  <div
                    v-if="listPrestataire.length == 0"
                    class="col-xl-3 col-sm-2 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <span class="text-danger"
                      >Il n'y a pas de prestataire disponible pour ce service
                      :)</span
                    >
                  </div>
                  <div
                    v-else
                    v-for="lp in listPrestataire"
                    :key="lp.prestataire.id"
                    class="col-xl-3 col-sm-2 wow fadeInUp"
                    data-wow-delay="0.1s"
                    @click.prevent="
                      redirectToDetailActeur(
                        lp.prestataire.id,
                        lp.metier.id,
                        lp
                      )
                    "
                  >
                    <div class="sr-card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div>
                            <img
                              :src="lp.prestataire.profile.photo"
                              alt=""
                              class="avatar-md rounded-circle img-thumbnail"
                            />
                          </div>
                          <div class="flex-1 ms-3">
                            <h5 class="font-size-16 mb-1">
                              <a href="#" class="text-dark">
                                {{ lp.prestataire.profile.nom }}
                                {{ lp.prestataire.profile.prenoms }}
                              </a>
                            </h5>
                            <span class="badge badge-soft-success mb-0">
                              {{ lp.metier.libelle }}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3 pt-1">
                          <!-- <p class="text-muted mb-0"><i class="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i> {{ lp.prestataire.profile.user.contact }}</p> -->
                          <!-- <p class="text-muted mb-0 mt-2"><i class="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i> {{ lp.prestataire.profile.user.email }}</p> -->
                          <p class="text-muted mb-0 mt-2">
                            <i
                              class="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"
                            ></i>
                            {{ lp.prestataire.profile.adresse }}
                          </p>
                          <p class="text-muted mb-0 mt-2">
                            <i
                              class="mdi mdi-road font-size-15 align-middle pe-2 text-primary"
                            ></i>
                            <span
                              v-if="lp.distance_en_km != 'Non disponible'"
                              style="
                                color: #f7a51b !important;
                                font-size: 12px !important;
                              "
                              >À
                              <b
                                style="
                                  color: #61c7f2 !important;
                                  font-weight: bold !important;
                                "
                                >{{ lp.distance_en_km }}</b
                              >
                              de vous</span
                            >
                            <span v-else style="color: red !important">{{
                              lp.distance_en_km
                            }}</span>
                          </p>
                          <!-- <div class="mt-3">
                                                    {{ data.prestataire.description }}
                                                </div> -->
                          <div class="description mt-3">
                            {{ lp.prestataire.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Category End -->
          </div>
          <!-- acteurs by domaines -->
        </div>
      </div>

      <!-- Footer Start -->
      <Footer />
      <!-- Footer End -->
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import router from "@/router";
export default {
  name: "DetailService",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      listPrestataire: [],
      subCategorieName: "",
      isLoading: false,
      visitorLat: "",
      visitorLng: "",
    };
  },
  beforeMount() {
    this.locatorFetch();
  },
  mounted() {
    this.subCategorieName = this.$route.params.subcatName;
  },
  methods: {
    async getPrestataireBySousCategorie(subCatId) {
      this.isLoading = true;
      await this.$store
        .dispatch("staticdata/searchPrestataireBySubCategorie", subCatId)
        .then(
          (response) => {
            this.isLoading = false;
            for (let index = 0; index < response.data.length; index++) {
              const element = response.data[index];

              const actorLat = element.prestataire.profile.localisation.lat,
                actorLng = element.prestataire.profile.localisation.long;
              var _d =
                this.visitorLat != "" && this.visitorLng
                  ? Math.round(
                      this._getDistanceFromLatLonInKm(
                        this.visitorLat,
                        this.visitorLng,
                        actorLat,
                        actorLng
                      ) * 100
                    ) /
                      100 +
                    " Km"
                  : "Non disponible";
              element.distance_en_km = _d;
            }
            this.listPrestataire = response.data;
          },
          (error) => {
            this.isLoading = false;
            console.log("error--- ", error);
          }
        );
    },
    async redirectToDetailActeur(prestataireId, metierId, selectedUserInfos) {
      // Conversion de proxy en objet
      var arrayData = Object.assign({}, selectedUserInfos),
        metierDara = Object.assign({}, arrayData.metier),
        prestaDara = Object.assign({}, arrayData.prestataire),
        profileData = Object.assign({}, prestaDara.profile),
        apiUserDara = Object.assign({}, profileData.user),
        userReData = {
          full_name: profileData.nom + " " + profileData.prenoms,
          contact: apiUserDara.contact,
          adresse: profileData.adresse,
          photo_url: profileData.photo,
          categorie: prestaDara.categorie,
          metier: metierDara.libelle,
          description: arrayData.description,
        },
        //
        dataToJson = JSON.stringify(userReData);
      var authData = Object.assign(
          {},
          JSON.parse(localStorage.getItem("authUser"))
        ),
        authToken = authData.token;
      if (authToken) {
        await localStorage.removeItem("selectedUserData");
        await localStorage.setItem("selectedUserData", dataToJson);

        router.push({
          name: "detailActeur",
          params: { prestataireId: prestataireId, metierId: metierId },
        });
      } else {
        router.push({ name: "Login" });
      }
    },
    _getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in kilometers
      var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in KM
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    locatorFetch() {
      this.isLoading = true;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.isLoading = false;
          this.visitorLat = position.coords.latitude;
          this.visitorLng = position.coords.longitude;
          this.getPrestataireBySousCategorie(this.$route.params.subcatId);
        },
        (error) => {
          this.isLoading = false;
          this.getPrestataireBySousCategorie(this.$route.params.subcatId);
          console.log(error.message);
        }
      );
    },
  },
};
</script>
