import StaticDataService from '../services/staticdata.service';

export const staticdata = {
    namespaced: true,
    state: {
        categories: [],
        prestataireservice: [],
    },
    actions: {
        async fetchCategorie({ commit }) {
            return StaticDataService.getCategories()
                .then(
                    response => {
                        commit('fetchCategorieSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchCategorieFailure');
                        return Promise.reject(error);
                    }
                );
        },
        async fetchPrestataireService({ commit }, data) {
            return StaticDataService.getPrestataireServices(data)
                .then(
                    response => {
                        commit('fetchPrestataireServiceSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchPrestataireServiceFailure');
                        return Promise.reject(error);
                    }
                );
        },

        async fetchSubCategorie({ commit }, categoryId) {
            return StaticDataService.getSubCategory(categoryId)
                .then(
                    response => {
                        commit('fetchCategorieSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchCategorieFailure');
                        return Promise.reject(error);
                    }
                );
        },

        async fetchCatAndSubCat({ commit }) {
            return StaticDataService.getCategoryAndSubCategory()
                .then(
                    response => {
                        commit('fetchCategorieSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchCategorieFailure');
                        return Promise.reject(error);
                    }
                );
        },
        async searchPrestataireBySubCategorie({ commit }, subCategoryId) {
            return StaticDataService.searchPrestataire(subCategoryId)
                .then(
                    response => {
                        commit('fetchCategorieSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchCategorieFailure');
                        return Promise.reject(error);
                    }
                );
        }
    },
    mutations: {
        fetchCategorieSuccess(state, categories) {
            state.categories = categories
        },
        fetchCategorieFailure(state) {
            state.categories = []
        },
        fetchPrestataireServiceSuccess(state, services) {
            state.prestataireservice = services
        },
        fetchPrestataireServiceFailure(state) {
            state.prestataireservice = []
        }
    }
};