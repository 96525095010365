import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";
import '../src/assets/lib/animate/animate.css'
import '../src/assets/lib/owlcarousel/assets/owl.carousel.min.css'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/style.css'
import "vue-toastification/dist/index.css"
import '../src/assets/css/dataTables.min.css';

// import '../src/assets/js/dataTables.bt5.min'
// import '../src/assets/js/dataTables.min.js'
import Toast from "vue-toastification";
import Vue3Geolocation from 'vue3-geolocation';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { FontAwesomeIcon } from './plugins/font-awesome'
import Shimmer from 'vue3-shimmer';

const options = {
    // You can set your default options here
};




createApp(App)
    .use(store)
    .use(router)
    .use(Shimmer)
    .use(Toast, options)
    .use(Vue3Geolocation)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDjV8aSDctyyV6xcWKPJS1C77AjyI9H7pg',
            libraries: "places"
        },
    })
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')