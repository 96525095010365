<template>
    <div class="Details Services">
        <Navbar title="Yajobici" />
        <div class="container-fluid p-0">
           <div style="margin-top: 80px !important">

              <!-- Header End -->
              <div class=" page-header mb-5" style="background-color: #61C7F2 !important">
                <div class="container pt-3 pb-4">
                    <h2 class="text-white mb-3 animated slideInDown">{{ categorieName }}</h2>
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb" style="color: #F7A51B !important">
                            <li class="breadcrumb-item" style="color: #F7A51B !important"><a href="#">Accueil</a></li>
                            <li class="breadcrumb-item"><a href="#">Formation</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">Catégories</li>
                        </ol>
                    </nav> -->
                </div>
              </div>
              <!-- Header End -->

              <div class="container">
                
                <!-- acteurs by domaines -->
                  <div class="mt-5 mb-5">
                <!-- Category Start -->
                  <div class="container-xxl py-5">
                    <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                        <div class="col-lg-8">
                            <h3 class="h1 m-15px-b">Explorer vos catégories</h3>
                            <p class="m-0px font-2">
                               Choisissez l'une des catégories ci-dessous pour trouver le prestataire dont vous avez besoin.
                            </p>
                        </div>
                    </div>
                    <div class="container">
                        <div v-if="isLoading" class="row">
                            <div class="col-md-12 text-center">
                                <span style="color: #F7A51B !important">
                                   Affichage des catégories en cours ...
                                </span>
                            </div>
                        </div>
                        <div v-else class="row d-flex justify-content-center g-4">
                            <div v-if="subCategories.length == 0" class="text-center py-4">
                                <span class="text-danger">Il n'y a pas de categories pour ce domaine :)</span>
                            </div>
                            <div v-else v-for="sc in subCategories" :key="sc.id" class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                <router-link :to="{ name: 'categorieService', params: { subcatId: sc.id, subcatName: sc.libelle }}" class="cat-item rounded p-4 m-4 border" style="border: 1px solid #F7A51B!important;border-radius: 5px !important;">
                                    <div class="row hover-top">
                                        <div class="col-md-"></div>
                                        <div class="col-md-11">
                                            <div class="p-20px-l media-body">
                                                <h6 class="m-5px-tb">{{ sc.libelle }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                  </div>
                <!-- Category End -->
                </div>
                <!-- acteurs by domaines -->

              </div>
           </div> 

            <!-- Footer Start -->
            <Footer/>
            <!-- Footer End -->
        </div>
    </div>  
</template>


<script>
    import Navbar from "../../components/Navbar.vue";
    import Footer from "../../components/Footer.vue";
    
    export default {
        name: 'DetailService',
        components: {
            Navbar,
            Footer,
        },
        data() {
            return {
                subCategories: [],
                categorieName: "",
                isLoading: false
            };
        },
        mounted(){
            this.getSousCategorie(this.$route.params.categorieId);
            this.categorieName = this.$route.params.categorieName;
            //console.log("FDFDS ", this.$route.params.categorieId);
        },
        methods: {
            async getSousCategorie(catId) {
                this.isLoading = true;
                await this.$store.dispatch("staticdata/fetchSubCategorie", catId)
                .then(
                    (response) => {
                        this.isLoading = false;
                        this.subCategories = response.data;
                    },
                    (error) => {
                        this.isLoading = false;
                        console.log("error--- ", error);
                    }
                );
            }
        }
    }
</script>
<style>
    .p-20px-l {
        padding-left: 20px;
    }
    .border-radius-15 {
        border-radius: 15px;
    }
    .white-color {
        color: #ffffff;
    }
</style>