<template>
    <div>
        <router-view />
    </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style scoped>

</style>
