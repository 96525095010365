<template>

<div class="Login bg-white">
    <div class="bg-white">
        <div class="login-root">
            <div class="box-root flex-flex flex-direction--column" style="min-height: 100vh;flex-grow: 1;"><div class="loginbackground box-background--white padding-top--64"><div class="loginbackground-gridContainer"></div></div>
            
                <div class="box-root padding-top--24 flex-flex flex-direction--column mt-5" style="flex-grow: 1; z-index: 9;">
                    <div class="formbg-outer mt-4">
                        <div class="formbg">
                            <div class="formbg-inner padding-horizontal--48">
                                <div class="row mt-2 mb-2">
                                  <div class="col-md-12">
                                    <div class="text-center">
                                      <h4>Espace utilisateur</h4>
                                      <div class="mt-3">
                                        <small>
                                          <p>Veuillez renseigner vos identifiants de connexion dans les champs ci-dessous.</p>
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <form class="py-3" @submit.prevent="handleLogin">
                                    <div class="field padding-bottom--24">
                                        <label for="phone">N° de Téléphone</label>
                                        <input v-model="phone" type="text" name="phone" required>
                                    </div>
                                    <div class="field padding-bottom--24">
                                        <div class="grid--50-50">
                                            <label for="password">Mot de passe</label>
                                        </div>
                                        <input v-model="password" type="password" name="password" required>
                                        <div class="reset-pass mt-2">
                                          <router-link to="/reinitialisation-de-mot-de-passe" style="color: #61C7F2 !important; font-size: 12px !important; text-decoration: underline !important;">Mot de passe oublié ?</router-link>
                                        </div>
                                    </div>
                                    <div class="field padding-bottom--24">
                                      <button v-if="loading" disabled class="btn btn-dark w-100" style="background: #c3c3c3; color: #FFFFFF;">Opération en cours ...</button>
                                      <button v-else type="submit" class="btn btn-warning w-100" style="background-color: #F7A51B !important; color: #FFFFFF">Connexion</button>
                                    </div>
                                </form>
                                <div class="row">
                                  <div class="col-md-6 mt-1">
                                    <div class="text-center">
                                      <router-link to="/">
                                        <button type="button" class="btn btn-sm btn-outline-warning w-100">
                                          <font-awesome-icon icon="home" />&nbsp;&nbsp;Accueil
                                        </button>
                                      </router-link>
                                    </div>
                                  </div>
                                  <div class="col-md-6 mt-1">
                                    <div class="text-center">
                                      <router-link to="/creation-de-compte">
                                        <button type="button" class="btn btn-sm btn-outline-info w-100">
                                          <font-awesome-icon icon="user-plus" />&nbsp;&nbsp;Inscription
                                        </button>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
//import Navbar from "../../components/Navbar.vue";
import { useToast } from "vue-toastification";
export default {
  name: 'Login',
  components: {
    //Navbar
  },
  data() {
    return {
      loading: false,
      phone: null,
      password: null
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      var data = {
        phone: '225' + this.phone,
        password: this.password
      }
      await this.$store.dispatch("auth/login", data)
      .then(
        (response) => {
          this.loading = false;
          if (response.success) {
            this.toast.success(response.message, {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            this.$router.push('/')
          }
        },
        (error) => {
          this.toast.error(error.response.data.message, {
            position: "top-center",
            timeout: 2500,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.loading = false;
        }
      );
    }
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  word-wrap: break-word;
}
body {
  min-height: 100%;
  background-color: #aeabab !important;
}
h1 {
  letter-spacing: -1px;
}
a {
  color: #61C7F2;
  text-decoration: unset;
}
.login-root {
  background: #fff;
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.loginbackground {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.flex-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.center-center {
  align-items: center;
  justify-content: center;
}
.box-root {
  box-sizing: border-box;
}
.flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}
.box-background--blue {
  background-color: #61C7F2;
}
.box-background--white {
  background-color: #ffffff;
}
.box-background--blue800 {
  background-color: #212d63;
}
.box-background--gray100 {
  background-color: #e3e8ee;
}
.box-background--cyan200 {
  background-color: #7fd3ed;
}
.padding-top--64 {
  padding-top: 64px;
}
.padding-top--24 {
  padding-top: 24px;
}
.padding-top--48 {
  padding-top: 48px;
}
.padding-bottom--24 {
  padding-bottom: 24px;
}
.padding-horizontal--48 {
  padding: 48px;
}
.padding-bottom--15 {
  padding-bottom: 15px;
}

.flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.formbg {
  margin: 0px auto;
  width: 100%;
  max-width: 448px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}
span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}
label {
  margin-bottom: 10px;
}
.reset-pass a,
label {
  font-size: 14px;
  font-weight: 600;
  display: block;
}
.reset-pass > a {
  text-align: right;
  margin-bottom: 10px;
}
.grid--50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.loginbackground-gridContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [left-gutter] 1fr
    [end];
  grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
  -ms-grid-rows: [top] 1fr [top-gutter] (64px) [bottom-gutter] 1fr [bottom];
  grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
  justify-content: center;
  margin: 0 -2%;
  transform: rotate(-12deg) skew(-12deg);
}

.field input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: 1px solid #acacac;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: #61C7F2 0px 0px 0px 0px;
}


@keyframes animationLeftRight {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes animationRightLeft {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>