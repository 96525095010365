<template>
    <div class="bodi">
        <!-- Hero (Start) -->
        <div class=" py-5 bg-hero" style="margin-bottom: 70px;">
            <div class="container py-3">
                <div class="row justify-content-start">
                    <div class="col-lg-8 text-center text-lg-start">
                        <h1 class="display-6 text-light">Conditions générales de yajobici</h1>
                        <div class="pt-2">
                            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                                <ol class="breadcrumb" style="color: #F7A51B;">
                                    <li class="breadcrumb-item"><router-link to="/" style="color: #F7A51B;">Accueil</router-link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Condition générales</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero (End) -->
        

        <div class="container" style="padding-left: 50px !important;padding-right: 50px !important;">

            <div class="definition">
                <h5>
                    <u>Définitions</u><br><br>
                </h5>
                <p>Les définitions suivantes sont applicables à:</p> 

                <p>
                    <span class="titre">Application</span>  : Désigne l’application mobile exploitée par YAJOBICI disponible sur IOS et Android,
                    réservée aux recruteurs et l’application YAJOBICI pro désigne l’application mobile exploitée par
                    YAJOBICI disponible sur IOS et Android et réservée plus aux prestataires.
                </p>

                <p>
                    <span class="titre">CGU :</span>
                    Désigne les présentes conditions générales d’utilisation
                </p>

                <p>
                    <span class="titre">Recruteur :</span>
                    Désigne toute personne physique ou morale (particulier ou entreprise) utilisant la
                    plateforme dans le but d’être mise en relation avec un prestataire pour bénéficier d’une prestation.
                </p>

                <p>
                    <span class="titre">Commande :</span>
                    Désigne une commande d’une prestation faite par le recruteur (particulier ou
                    entreprise)
                </p>
                <p>
                    <span class="titre">Compte</span>
                    Désigne l’espace numérique dédiée et individualisé d’un utilisateur sur la plateforme.
                </p>
                <p>
                    <span class="titre">Plateforme :</span> 
                    Désigne la plateforme de mise en relation opérée par YAJOBICI accessible depuis le
                    site, l’application mobile Android et l’application web, permet de réaliser les services.
                </p>
                <p>
                    <span class="titre">Prestataire :</span> 
                    Désigne un prestataire de service (particulier ou entreprise) service exerçant par
                    l’intermédiaire d’une société ou du Statut d’indépendant, régulièrement inscrit sur la plateforme et
                    disposant des compétences nécessaires pour effectuer des prestations.
                </p>
                <p>
                    <span class="titre">Prestation :</span> 
                    Désigne la prestation de service rendue par un prestataire à un recruteur (société ou
                    individu) dans divers domaines notamment du bien être personnel, de l’entretien de maison jusqu’au
                    dépannage
                </p>
                <p>
                    <span class="titre">Service :</span> 
                    Désigne l’ensemble de services rendu par YAJOBICI aux utilisateurs
                </p>
                <p>
                    <span class="titre">Utilisateur :</span> Désigne un prestataire qui utilise la plateforme dans le but d’attirer de nouveaux clients
                    et un recruteur qui recherche des prestataires compétents et à moindre cout.
                </p>
            </div><br><br>

            <div class="terms">

                <div class="terms_two">
                    <h6>2. Objet et portée des CGU</h6> 
                    <p>
                        YAJOBICI exploite la plateforme, qui permet aux prestataires d’être mise en relation avec des
                        recruteurs, afin que ceux-ci bénéficient de prestations. Les présentes conditions générales
                        d’utilisation ont pour objet la définition des modalités d’accès et d’utilisation
                        De la plateforme, ainsi que de définir les droits et obligations des prestataires et les recruteurs en
                        lien avec l’utilisation de la plateforme.
                        Les CGU ne régissent par la relation contractuelle entre un recruteur et un prestataire pour la
                        réalisation des prestations. En qualité d’opérateur de la plateforme en ligne, YAJOBICI n’assume
                        aucune obligation au titre des relations contractuelles entre le recruteur et le prestataire.
                    </p>
                </div>

                <div class="terms_three">
                    <h6 class="mt-4">3. Inscription et acceptation des CGU</h6>
                    <strong class="m-4" > 3.1. Pour le recruteur</strong><br><br>

                    <p class="three1">
                        Le recruteur (particulier ou entreprise) peut créer son compte et s’inscrire sur la plateforme, au cours
                        du processus d’une réservation ou d’une commande en cliquant sur « connexion » ou « inscription »
                        s’il ne possède pas de compte sur la plateforme
                    </p>
                    <p>Le recruteur doit fournir les informations à la création de son compte lors de sa première connexion,
                        et notamment le nom, le prénom, adresse email, le numéro de téléphone, le genre(sexe), son niveau
                        d’étude, une photo</p>
                    <p>
                        Le recruteur (particulier ou entreprise) garantit à YAJOBICI qu’il dispose du pouvoir et de la capacité
                        pour créer le compte et utiliser les différents services.
                    </p>
                    <p>Lors du processus de création du compte, le recruteur s’engage à lire les CGU lorsqu’il matérialise
                        son consentement à celles-ci en cochant la case « j’accepte les conditions générales d’utilisations ».
                    </p>
                    <p>
                        Le recruteur est libre d’accepter ou de refuser les CGU, à compter de son acceptation et/ ou à l’issue
                        du processus de commande ou de réservation, le recruteur reçoit un courrier électronique
                        confirmant la création de son compte.
                    </p>

                    <strong class="m-4" > 3.2. Pour le prestataire</strong><br><br>
                    <p>Le prestataire (particulier ou entreprise) peut s’inscrire sur la plateforme et créer son compte via les
                    sites, dans la section dédiée aux prestataires, ou via l’application YAJOBICI.</p>
                    <p>
                        Lors de sa première connexion, le prestataire doit fournir les informations suivantes, pour la création
                        de son compte : renseignez son profil comme demande la fiche d’inscription. Pour les entreprises : le
                        nom, le logo, l’attestation d’existence(DFE). Parmi les choix proposés, le prestataire garantit à
                        YAJOBICI qu’il dispose du pouvoir et de la capacité pour créer le compte et utiliser les services.
                    </p>
                    <p>Lors du processus de création du compte, le prestataire s’engage à lire les CGU lorsqu’il matérialise
                        son consentement à celles-ci en cochant la case « j’accepte les conditions générales d’utilisations ».
                    </p>
                    <p>Le prestataire est libre d’accepter ou de refuser les CGU. À compter de son acceptation, le
                    prestataire reçoit un courrier électronique confirmant la création du compte.</p>
                </div>

                <div class="terms_four">
                    <h6 class="mt-4">4. Evaluation des services et modification des CGU</h6>
                    <p>
                        Pour améliorer son fonctionnement et sa qualité, YAJOBICI fait évaluer les caractéristiques et les
                    fonctionnalités de la plateforme et des services. YAJOBICI fait évoluer les caractéristiques et des
                    fonctionnalités de la plateforme et des services. YAJOBICI peut modifier unilatéralement les CGU
                    concernant les aspects techniques de la plateforme et les services des lors qu’il n’en résulte
                    augmentation du prix ni altération de la qualité et que les caractéristiques auxquelles l’utilisateur à
                    subordonnée son engagement ont pu figurer dans les CGU.
                    </p>
                    <p>
                        Pour les autres modifications des CGU et des services, YAJOBICI informera l’utilisateur, par tout
                    moyen, au moins 15 jours avant leur entrée en vigueur.
                    </p>
                    <p>
                        En l’absence d’objection par l’utilisateur dans le délai, les modifications seront considérées comme
                    étant lues et acceptées. En cas d’objection ou de refus, les CGU seront immédiatement résiliés et
                    l’utilisateur devra cesser d’utiliser la plateforme et les services, sous réserve de la parfaite exécution
                    des obligations en vigueur (en particulier, les utilisateurs devront honorer les commandes en cours).
                    </p>
                </div>

                <div class="terms_five">
                    <h6 class="mt-4">5. Accès, usage et sécurité du compte</h6>
                    <p>
                        La plateforme est disponible à tous mais l’accès est exclusivement réservé aux utilisateurs.
                        L’utilisateur. Chaque utilisateur comprend et accepte qu’il est responsable de l’accès et de
                        l‘utilisation de son compte. Sauf défaillance technique imputable à YAJOBICI ou cas de force
                        majeure. Chaque utilisateur est responsable de la confidentialité de ses données de connexion de
                        nature à assurer la sécurité et la confidentialité des données de connexion.
                    </p>
                    <p>
                        S’ily a des raisons de croire que ses données de connexion (en particulier, son identifiant ou son mot
                        de pass) ont été perdues, volées, détournées ou comprises d’une quelconque manière, ou en cas
                        d’utilisation non autorisée de son compte, l’utilisateur doit immédiatement notifier YAJOBICI. Dans
                        une tel situation l’utilisateur autorise à YAJOBICI à prendre toute les mesures appropriées pour
                        empêcher tout nouvel accès au compte.
                    </p>
                </div>

                <div class="terms_six">*
                    <h6 class="mt-4">6. Realisation des prestations</h6>
                    <strong class="m-4" > 6.1. Commandes</strong><br><br>

                    <p>
                        Le recruteur peut effectuer des commandes sur le site ou l’application. Il doit sélectionner une ou
                        plusieurs prestation(s) particulier(s) au sein des catégories de prestations proposées et renseigner ladate, l’heure souhaitée. Les prestations ne peuvent être réalisées qu’aux horaires convenues entre le
                        recruteur et le prestataire, sauf au cas où le recruteur modifie l’heure de la prestation.
                    </p>
                    <p>
                        Avant validation de la commande, le recruteur a accès à un récapitulatif de celle-ci et peut renseigner
                        des demandes particulières ou donner des indications.
                    </p>
                    <strong class="m-4" > 
                    6.2. Mise en relation des recruteurs et des prestataires
                    </strong><br><br>

                    <p>
                        Apres validation, la commande est transmise, grâce l’algorithme de YAJOBICI ; aux prestataires
                        disponibles et correspondant au mieux à la demande du client. Une fois le prestataire à accepter la
                        commande, celle-ci est considérée comme validée.</p>
                    <p>
                        Le prestataire est sélectionné pour le recruteur en fonction sur la base d’un score qui varie
                        notamment en fonction des critères suivants :</p>
                    <p>
                        - La capacité de fidélisation des recruteurs par le prestataire <br>
                        - La note du prestataire attribuer par le recruteur, et <br>
                        - Le nombre d’annulation des commandes 
                    </p>
                    <p>
                        Les prestataires sont également attribués en fonction de la localisation des prestataires et des
                        recruteurs.
                    </p>

                    <p>
                        Lorsqu’il reçoit une proposition de prestation, le recruteur demeure libre d’accepter ou de refuser en
                        utilisant la fonctionnalité correspondante sur la plateforme. Passé le délai le prestataire sera réputé
                        avoir refusé la proposition.
                    </p>
                    <p>
                        Dès qu’une commande est réalisée, YAJOBICI sollicite plusieurs prestataires. Les prestataires
                        sollicités en priorité sont ceux bénéficiant des scores élevés sur la base des critères ci-dessous. Plus la
                        commande sera urgente, plus les prestataires seront sollicités rapidement afin de satisfaire la
                        commande.
                    </p>
                    <p>
                        En l’absence de réponse d’un prestataire dans un temps qui dépend de l’urgence de la commande, la
                        commande est ensuite proposée à d’autres prestataires ; jusqu’à ce qu’elle soit acceptée par ‘un
                        d’entre eux.
                    </p>
                    <strong class="m-4" > 
                        6.3. Annulationd'une commande
                    </strong><br><br>

                    <p>
                        Lorsqu’une commande est validée plus de 14 jours à l’avance conformément au paragraphe cidessous, le prestataire bénéficie du droit de rétraction légalement prévu. Il lui suffit d’annuler la
                        commande sur la plateforme, en utilisant la fonctionnalité correspondante sur le site ou l’application,
                        et celle-ci sera annulée sans frais.
                    </p>
                    <p>
                        YAJOBICI décline toute responsabilité et aucun frais ne sera versé, au cas où Ilya annulation après
                        l’heure du rendez-vous prévu soit par le prestataire ou par le recruteur.
                    </p>

                    <strong class="m-4" > 
                        6.4. Déroulement des prestations
                    </strong><br><br>
                    <p>
                        YAJOBICI n’intervient pas au moment de la prestation, qui relève de la seule responsabilité et des
                        compétences du prestataire. A l’issue d’une prestation, le recruteur évalue le prestataire, par
                        l’intermédiaire d’un système de notation. Les évaluations ont vocations à être définitives et ne sont
                        supprimés exceptionnellement après une demande expresse de leur auteur. Le prestataire évalue
                        également le recruteur es avis laissées par les prestataires ne sont pas publics.
                    </p>
                </div>

                <div class="terms_seven">
                    <h6 class="mt-4">7.Conditions financières</h6>
                    <strong class="m-4" > 
                        7.1. Prix des prestations
                    </strong><br><br>
                    <p>
                        Le prix des prestations est fixé par le prestataire. Le recruteur doit régler les prestations par le moyen
                        de paiement qui sera convenue avec le prestataire.
                    </p>
                    <p>
                        La rémunération du prestataire correspond au montant qu’il aura proposé au recruteur avant qu’il
                        n’accepte une prestation. YAJOBICI ne perçoit aucun pourcentage sur la somme versée au
                        prestataire après la réalisation de la prestation.
                    </p>
                    <strong class="m-4" > 
                        7.2. Mode de paiement des différents pass
                    </strong><br><br>
                    <p>
                        Pour la gestion des flux financiers, YAJOBICI a recours à un partenaire de services de paiement qui
                        commercialise une solution permettant la gestion des flux de paiement entre YAJOBICI, les
                        prestataires et les recruteurs.
                    </p>
                </div>

                <div class="terms_eight">
                    <h6 class="mt-4" >8.1. Engagement de YAJOBICI</h6>
                    <p>
                        YAJOBICI s’engage à mettre la plateforme et les services à la disposition des utilisateurs ainsi qu’à
                        faire ses meilleurs efforts pour assurer l’accessibilité et le bon fonctionnement.
                    </p>
                    <p>
                        A ce titre, YAJOBICI s’engage à ce que la plateforme et les services soient accessibles. Sauf de cas de
                        force majeure au comportement imprévisibles et insurmontables d’un tiers, et sous réserve des
                        éventuelles pannes et interventions de maintenances et de mise à jour nécessaires au bon
                        fonctionnement de la plateforme et la fourniture des services.
                    </p>
                    <p>
                        YAJOBICI met à la disposition des utilisateurs un service de support pour répondre aux questions et
                        fournir une assistance en cas de difficulté. Pour toute question ou demande d’information
                        concernant la plateforme ou les services, l’utilisateur peut contacter YAJOBICI en lui adressant uncourrier électronique ou par téléphone. Au cas d’un disfonctionnement ou d’une anomalie
                        perturbant le bon fonctionnement de la plateforme ou des services, YAJOBICI s’engage à faire des
                        meilleurs efforts pour a situation.
                    </p>

                    <h6 class="mt-4" > 
                        8.2. Responsabilité de YAJOBICI
                    </h6>

                    <p>
                        Les utilisateurs reconnaissent et acceptent que la responsabilité de YAJOBICI limitée à son rôle
                        d’opérateur de la plateforme en ligne.
                    </p>
                    <p>
                        En aucun cas YAJOBICI n’engagera sa responsabilité au titre des prestations, puisqu’en sa qualité
                        d’intermédiaire numérique, YAJOBICI n’est pas charger de leur réalisation.
                    </p>
                    <p>
                        Par ailleurs, YAJOBICI n’est pas responsables des difficultés ou impossibilités momentanées d’accès à
                        la plateforme et ai compte ou aux services si elles ont pour origine une faute d’un utilisateur, des
                        circonstances qui lui sont extérieures, force majeure, ou encore qui seraient dues à des perturbations
                        réseaux de télécommunication ou aux limites inhérentes au fonctionnement d’un service sur
                        internet.
                    </p>
                </div>

                <div class="terms_nine">
                    <h6 class="mt-4">9.1. Engagement des utilisateurs</h6>
                    <strong class="m-4" > 
                        9.2. Engagement commun au utilisateurs
                    </strong><br><br>
                    <p>
                        L’utilisateur s’engage expressément à :
                        Respecter les lois et règlements en vigueur et à ne pas porter atteinte à l’ordre public, aux bonnes
                        mœurs ou aux droits de tiers et à n’enfreindre aucune disposition législatives ou règlementaires ;
                    </p>
                    <p>Ne permettre à aucun tiers d’utiliser son compte ;</p>
                    <p>
                        Ne pas publier contenues injuriés, diffamant, dénigrant, calomnieux, contraires aux bonnes mœurs,
                        contrefaisant, portant atteinte à l’ordre public et aux droits des tiers, susceptible de porter atteinte
                        aux droits, à la répartition et à l’image de YAJOBICI ou d’un autre utilisateur ;
                    </p>
                    <p>
                        Ne pas utiliser la plateforme ou les services de manière frauduleuse (ex : un faux compte ; une fausse
                        identité) et /ou par nuire aux intérêts de YAJOBICI ou d’un autre utilisateur ;
                    </p>
                    <p>
                        Ne pas céder ou transférer d’une quelconque manière son compte à un tiers ;
                        Fournir à YAJOBICI toute les informations nécessaires à la bonne exécution des services et garantir
                        leur exactitude ;
                    </p>
                    <p>
                        Faire une utilisation loyale de la plateforme er des services, c’est à dire de participer au processus
                        d’inscription, de la commande ou encore de notation.
                    </p>
                </div>

                <div class="terms_ten">
                    <h6 class="mt-4">10. Responsabilité des utilisateurs</h6>

                    <p>
                        L’utilisateur est responsable de son utilisation de la plateforme et des services, de sorte qu’il répond
                        de tout préjudice qu’il et susceptible de causer du fait de son comportement et informations
                        inexactes, incomplètes, trompeuses qu’il fournirait lors de son inscription ou l’absence de mise à jour
                        de ces informations, ce dont il assume seul la responsabilité.
                    </p>
                    <p>
                        De la négociation, de la conclusion et de l’exécution des contrats qui interviennent le cas échéant
                        entre eux pour la réalisation des prestations. YAJOBICI n’étant qu’une simple plateforme de mise en
                        relation.
                    </p>
                </div>

                <div class="terms_eleven">
                    <h6 class="mt-4">11.Durée, suspension et résiliation</h6>
                    <strong class="m-4" > 
                        11.1. Durée de la CGU
                    </strong><br><br>
                    <p>
                        Les CGU sont conclues pour des durée indéterminée et entrent en vigueur à compter de leur
                        acceptation par l’utilisateur.
                    </p>

                    <strong class="m-4" > 
                        11.2. Suspension
                    </strong><br><br>

                    <p>
                        YAJOBICI peut suspendre temporairement et sans préavis le compte d’un utilisateur à la plateforme
                        si celui-ci a manqué à l’un de ses engagements au titre CGU. Passé une période d’investigation au
                        cours de laquelle, YAJOBICI s’assurera, le cas échéant la véracité des manquements allégués, l’accès à
                        la plateforme pourra être établir ou les CGU pourront être résiliées conformément aux stipulations
                        ci-dessous.
                    </p>

                    <strong class="m-4" > 
                        11.3. Autonomie et indépendence
                    </strong><br><br>

                    <p>
                        Les prestataires exerçant leur activité en totale autonomie et indépendance, donc supportent les
                        risques de son activité.
                    </p>
                    <p>
                        En tant que prestataire commercial, le prestataire est libre de choisir ses jours d’activité et de repos
                        ainsi que d’utiliser le matériel de son choix pour réaliser les prestations.
                    </p>
                    <p>
                        Le prestataire n’est soumis à aucune obligation d’exclusivité vis-à-vis de YAJOBICI. Le prestataire est
                        libre de conclure et de fournir ses prestations à sa clientèle personnelle. Le prestataire n’est soumis à
                        aucune obligation de chiffre d’affaire ou de niveau d’activité minimum.
                    </p>
                </div>
                
                <div class="terms_twelve">
                    <h6 class="mt-4">12. Port fort</h6>

                    <p>
                        Lorsqu’il effectue une commande pour le compte d’un tiers ? Le recruteur s’engage à portée à la
                        connaissance et à faire accepter les présentes CGU au tiers qui bénéficiera de la (les)prestation(s) ciaprès ,le « bénéficiaire ».Le recruteur se porte fort du respect des CGU par le beneficiaire.En cas de
                        dommage causé par le bénéficiaire consécutif au non-respect des CGU,le recruteur accepte de
                        garantir YAJOBICI contre toute action, demande, revendication de toute nature contre YAJOBICI,
                        formulé par un prestataire ou tout autre tiers
                    </p>
                </div>

            </div>
        <div>

        <Footer/>
        </div>
        </div>
    </div>
</template>
    
    
    <script>
    // import Navbar from "../../components/Navbar.vue";
    // import Footer from "../../components/Footer.vue";
    
    export default {
    name: 'DetailService',
    components: {
    // Navbar,
    // Footer,
    },
    }
    </script>
    
    <style scoped>
    
    /********** style CSS **********/
    
    .bodi {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #54595F;
    background-color: #fbfdff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    
    }
    
    .btn {
    font-weight: 700;
    transition: .10s;
    background-color:  #F7A51B; /* Green */
    border: none;
    color: rgb(255, 255, 255);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    
    }
    
    .btn:hover {
    -webkit-box-shadow: 0 8px 6px -6px #555555;
    -moz-box-shadow: 0 8px 6px -6px #555555;
    box-shadow: 0 8px 6px -6px #555555;
    }
    
    .btn.btn-secondary {
    color: #a73535;
    
    }
    
    .bg-hero {
    background-size: contain;
    background-color: #60e4ff;
    }
    .bg-hero2 {
    background-size: contain;
    background-color: #60e4ff;
    
    }
    
    
    
    @media (max-width: 991.98px) {
    .bg-hero {
    background-size: cover;
    }
    }
    
    .about-start,
    .about-end {
    background-size: contain;
    background-color: #60e4ff;
    }
    
    @media (min-width: 992px) {
    .about-start {
    position: relative;
    margin-right: -90px;
    z-index: 1;
    }
    
    .about-end {
    position: relative;
    margin-left: -90px;
    z-index: 1;
    }
    }
    
  
    
    /* style about recruteur */
    
  
    
    div .titlle{
        line-height: -0px !important;
        color: #fff;
        padding: 50px;
        font-weight: 900;
    }
    
    
    
    div.parent {
      width:100%;
      display:block;
      /* font-size:50px; */
      text-transform: uppercase;
      text-align:center;
    }
    
    .block1 {
      font-size:60px;
      z-index: 1;
      color:#1b72a111;
      font-weight: 700 !important;
    }
    
    .block2 {
      font-size:25px;
      color: #283779 !important;
      z-index:0;
      font-weight: 700 !important;
      margin-top:-60px;
    }
    
    .accordion-body{
        color: #f7a61bc0 !important;
    }
    .accordion-item{
        background-color: white !important;
        border-bottom: 1px solid #61C7F2 !important; 
        margin-bottom: 15px !important;
        border-radius: 15px !important;
    }
    .accordion-button{
        background-color: white !important;
        color: #283779cf !important;
    }
    
    

    
    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 2vmin;
        font-weight: 600;
        font-size: 3vmin;
        color: #1c1c1e;
    }
    
    .pens-light {
        color: #1a238f;
        text-decoration: none;
        background: linear-gradient(to right, #1a238f 60%, #ff3300 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    

    .titre{
        font-weight: 700 !important;
    }
    </style>