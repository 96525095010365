import UserService from "../services/user.service";

export const user = {
    namespaced: true,
    state: {
        data: [],
    },
    actions: {
        async changeLocationRequest({ commit }, locData) {
            return UserService.changeLocation(locData).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
        async updatePasswordRequest({ commit }, pwdData) {
            return UserService.changePassword(pwdData).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
        async contactUsRequest({ commit }, data) {
            return UserService.contactUs(data).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
        async retrieveUserInfo() {
            return UserService.getAuthUserInfos().then(
                (response) => {
                    return Promise.resolve(response);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        async retrieveUserService() {
            return UserService.getAuthUserServices().then(
                (response) => {
                    return Promise.resolve(response);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        async changeUserAvatar({ commit }, data) {
            return UserService.avatarUpdate(data).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
        async editUserInfos({ commit }, data) {
            return UserService.editInformation(data).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
        async verifyPasswordForAccountDeletion({ commit }, currentPwd) {
            return UserService.userAccountDeletionVerification(currentPwd).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
        async confirmAccountDeletion() {
            return UserService.userAccountDeletionConfirmation().then(
                (response) => {
                    return Promise.resolve(response);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        operationSuccess(state, d) {
            state.data = d;
        },
        operationFailure(state) {
            state.data = [];
        },
    },
};