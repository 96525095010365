<template>
    <header>

        <router-link to="/">
            <button type="button" class="btn" style="color: #FFFFFF !important; 
            background-color: #F7A51B !important; font-size: xx-small !important;">
                Page d'accueil
            </button>
        </router-link>

        <div class="search-wrapper">
            <span class="ti-search"></span>
            <div v-if="data == null">
                <free-style-shimmer
                    :is-loading="true"
                    height="20px"
                    width="150px"
                    border-radius="2px"
                    color="#bdbdbd"
                />
            </div>
            <strong v-else>{{ data.nom }} {{ data.prenoms }}</strong>
        </div>
        
        <div class="social-icons" v-if="data == null">
            <free-style-shimmer
                :is-loading="true"
                height="40px"
                width="40px"
                border-radius="50px"
                color="#bdbdbd"
            />
        </div>
        <div v-else class="social-icons" v-bind:style="{ backgroundImage: 'url(' + data.photo + ')', height: '38px', width: '38px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '50%'}">
            <span class="ti-bell"></span>
            <span class="ti-comment"></span>
            <div></div>
        </div>
    </header>
</template>
  
<script>
    export default {
        name: 'UserNavbar',
        props: {
            data: Object
        }
    }
</script>
<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
  
  :root {
      --main-color: #027581;
      --color-dark: #1D2231;
      --text-grey: #8390A2;
      
  }
  
  * {
      font-family: 'Poppins', sans-serif;
      margin: 0;
      padding: 0;
      text-decoration: none;
      list-style-type: none;
      box-sizing: border-box;
  }
  
  header {
      position: fixed;
      left: 200px;
      top: 0;
      z-index: 100;
      width: calc(100% - 200px);
      background: #fff;
      height: 60px;
      padding: 0rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      transition: left 500ms;
  }
  
  .search-wrapper {
      display: flex;
      align-items: center;
  }
  
  .search-wrapper input {
      border: 0;
      outline: 0;
      padding: 1rem;
      height: 38px;
  }
  
  .social-icons {
      display: flex;
      align-items: center;
  }
  
  .social-icons span,
  .social-icons div {
      margin-left: 1.2rem;
  }
</style>