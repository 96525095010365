import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faToggleOff,
    faList,
    faHomeAlt,
    faHouse,
    faCog,
    faFolder,
    faEdit
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faToggleOff,
    faList,
    faHomeAlt,
    faHouse,
    faCog,
    faFolder, faEdit);

export { FontAwesomeIcon };