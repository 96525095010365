<template>
    <router-link :to="{ name: 'detailService', params: { categorieId: elId, categorieName: catTitle }}" class="cat-item rounded p-4" href="">
        <img class="img-fluid rounded" style="height: 100px !important;width: 100% !important;" :src="catImage" alt="">
        <h6 class="mb-1 mt-4">{{ catTitle }}</h6>
        <!-- <p class="mb-0" style="color:  #2B9BFF;">{{ catOfferNbr }} Offre(s)</p> -->
    </router-link>
</template>

<script>

export default {
  name: 'CategoryCard',
  props: {
    catTitle: String,
    catOfferNbr: String,
    catImage: String,
    elId: Number
  }
}
</script>