<template>
  <div class="Details Services boddy">
    <Navbar title="Yajobici" />

    <div class="" style="margin-top: 80px !important">
      <div
        class="page-header mb-5 p-5"
        style="background-color: #61c7f2 !important"
      >
        <div class="container pt-3 pb-4">
          <h2 class="text-white mb-2 animated slideInDown">Contactez-nous</h2>
        </div>
      </div>

      <!-- Contact start-->
      <div class="container-fluid pt-5">
        <div class="container">
          <div class="row mb-5">
            <div class="col-lg-4">
              <div
                class="bg-white rounded d-flex flex-column align-items-center justify-content-center text-center"
                style="height: 200px;"
              >
                <div
                  class="d-flex align-items-center justify-content-center rounded-circle mb-4"
                  style="width: 100px; height: 70px; transform: rotate(-15deg); background-color: #F7A51B !important"
                >
                  <i
                    class="fa fa-2x fa-location-arrow text-white"
                    style="transform: rotate(15deg)"
                  ></i>
                </div>
                <h6 class="mb-0">00225 Angré, Abidjan, COTE D'IVOIRE</h6>
              </div>
            </div>
            <div class="col-lg-4">
              <div
                class="bg-white rounded d-flex flex-column align-items-center justify-content-center text-center"
                style="height: 200px"
              >
                <div
                  class="d-flex align-items-center justify-content-center bg-warning rounded-circle mb-4"
                  style="width: 100px; height: 70px; transform: rotate(-15deg); background-color: #F7A51B !important"
                >
                  <i
                    class="fa fa-2x fa-phone text-white"
                    style="transform: rotate(15deg);"
                  ></i>
                </div>
                <h6 class="mb-0">+225 0767561377</h6>
              </div>
            </div>
            <div class="col-lg-4">
              <div
                class="bg-white rounded d-flex flex-column align-items-center justify-content-center text-center"
                style="height: 200px"
              >
                <div
                  class="d-flex align-items-center justify-content-center bg-warning rounded-circle mb-4"
                  style="width: 100px; height: 70px; transform: rotate(-15deg); background-color: #F7A51B !important"
                >
                  <i
                    class="fa fa-2x fa-envelope-open text-white"
                    style="transform: rotate(15deg);"
                  ></i>
                </div>
                <h6 class="mb-0">yajobici@agilestelecoms.com</h6>
              </div>
            </div>
          </div>

          <div class="container-xxl py-5">
            <div class="container">
              <div class="row g-5">
                <div
                  class="col-lg-6 wow fadeIn"
                  data-wow-delay="0.1s"
                  style="
                    visibility: visible;
                    animation-delay: 0.1s;
                    animation-name: fadeIn;
                  "
                >
                  <p
                    class="d-inline-block border rounded fw-semi-bold py-1 px-3"
                    style="color: #f7a51b; border-radius: 5px !important"
                  >
                    Contact
                  </p>
                  <h1 class="display-5 mb-4">Avez-vous des quesions?</h1>
                  <p class="mb-4">
                    N'hesitez pas à nous contacter et laisser un message ici.
                  </p>
                  <form @submit.prevent="submitContactUsForm">
                    <div class="row g-3">
                      <div class="col-md-6">
                        <div class="form-floating">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder="Email"
                            v-model="email"
                            required
                          />
                          <label for="email">Adresse email</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="phone_number"
                            placeholder="Numero de téléphone"
                            v-model="phone"
                            required
                          />
                          <label for="phone_number">Numero de téléphone</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="subject"
                            placeholder="sujet"
                            v-model="subject"
                            required
                          />
                          <label for="subject">Sujet</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="Ecrivez ici"
                            id="message"
                            style="height: 100px"
                            v-model="message"
                            required
                          ></textarea>
                          <label for="message">Message</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <button
                          class="btn py-3 px-5 disabled"
                          style="
                            background-color: #61c7f2;
                            color: #ffffff;
                            border-radius: 5px !important;
                          "
                          type="submit"
                          v-if="loading"
                        >
                          Operation en cours ...
                        </button>
                        <button
                          class="btn py-3 px-5"
                          style="
                            background-color: #61c7f2;
                            color: #ffffff;
                            border-radius: 5px !important;
                          "
                          type="submit"
                          v-else
                        >
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div
                  class="col-lg-6 wow fadeIn"
                  data-wow-delay="0.5s"
                  style="
                    min-height: 450px;
                    visibility: visible;
                    animation-delay: 0.5s;
                    animation-name: fadeIn;
                  "
                >
                  <div class="position-relative rounded overflow-hidden h-100">
                    <iframe
                      class="position-relative w-100 h-100"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127118.32333851764!2d-4.049877337508402!3d5.348446107419886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1ea5311959121%3A0x3fe70ddce19221a6!2sAbidjan!5e0!3m2!1sfr!2sci!4v1664884609047!5m2!1sfr!2sci"
                      frameborder="0"
                      style="min-height: 450px; border: 0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Contact End -->

      <!-- Footer Start -->
      <Footer />
      <!-- Footer End -->
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";
export default {
  name: "ContactUs",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading: false,
      email: "",
      phone: "",
      subject: "",
      message: "",
      apimessage: "",
    };
  },
  methods: {
    async submitContactUsForm() {
      this.loading = true;
      var data = {
        email: this.email,
        phone: this.phone,
        subject: this.subject,
        message: this.message,
      };
      console.log(data, " data");
      await this.$store.dispatch("user/contactUsRequest", data).then(
        (response) => {
          this.loading = false;
          this.toast.success(response.message, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        (error) => {
          this.loading = false;
          this.apimessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;

          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message +
                  " " +
                  JSON.stringify(error.response.data.data)) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe4fd;
  appearance: none;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
</style>
