<template>
    <div class="Home bg-white">
        <Navbar title="Yajobici" />
        <div class="container-fluid p-0">
            <!-- Category Start -->
            <br><br>
            <div class="container-xxl py-5 mt-5">
                <h2 class="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Nos domaines d'activités</h2>
                <div v-if="loading" class="container text-center">
                    <span style="color: #F7A51B !important">
                        Affichage en cours ...
                    </span>
                </div>
                <div v-else class="container">
                    <div v-if="categories.length == 0" class="text-center">
                        <span class="text-danger">Désolé, il n'y a pas de domaine disponible :)</span>
                    </div>
                    <div v-else class="row justify-content-center text-center g-4">
                        <div v-for="index in categories" :key="index.id" class="col-md-3  wow fadeInUp" data-wow-delay="0.1s">
                            <CategoryCard 
                                :catTitle="index.libelle" 
                                :catOfferNbr="index.id" 
                                :catImage="index.photo"
                                :elId="index.id"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Category End -->

            <!-- Footer Start -->
            <Footer/>
            <!-- Footer End -->

        </div>
    </div>  
</template>


<script>
    import Navbar from "../../components/Navbar.vue";
    import Footer from "../../components/Footer.vue";
    import CategoryCard from "../../components/CategoryCard.vue";
    export default {
        name: 'Home',
        components: {
            Navbar,
            Footer,
            CategoryCard,
        },
        data() {
            return {
                loading: false,
                categories: []
            };
        },
        mounted(){
            this.categoriesList()
          },
        methods: {
            async categoriesList() {
                this.loading = true;
                await this.$store.dispatch("staticdata/fetchCategorie")
                .then(
                    (response) => {
                        this.loading = false;
                        this.categories = response.data;
                    },
                    (error) => {
                        this.loading = false;
                        console.log("error--- ", error);
                    }
                );
            }
        }
    }
</script>