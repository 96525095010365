import axios from 'axios'
import { baseURL } from "../config/apiBase"

class RegisterService {
    createRecruteur(d) {
        let formData = new FormData();

        for (var key of Object.keys(d)) {
            formData.append(key, d[key]);
        }

        return axios.post(
                baseURL + 'recruteur/register', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
            .then(response => {
                return response.data;
            });
    }

    createPrestataire(d) {
        let formData = new FormData();

        for (var key of Object.keys(d)) {
            formData.append(key, d[key]);
        }

        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ' : ' + pair[1]);
        // }

        return axios.post(baseURL + 'prestataire/register', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then(response => {
                return response.data;
            });
    }
}

export default new RegisterService();