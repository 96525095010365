<template>
  <div class="Offre" style="margin-top: 100px !important">
    <Navbar title="Yajobici" />

    <div class="container-fluid p-0">
      <div class="container">
        <div class="main-body">
          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb" class="main-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" style="color: #4e585d !important"
                  >Accueil</router-link
                >
              </li>
              <li class="breadcrumb-item">
                <router-link
                  to="/nos-services"
                  style="color: #4e585d !important"
                  >Prestataires</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Détails
              </li>
            </ol>
          </nav>
          <!-- /Breadcrumb -->

          <div v-if="loading" class="row text-center">
            <div class="col-md-12">
              <span>Chargement des services en cours ...</span>
            </div>
          </div>

          <div v-else class="tabs">
            <!-- tabs 1 start -->
            <div class="tab-2">
              <label for="tab2-1" style="color: #4e585d !important"
                >Je me présente</label
              >
              <input
                id="tab2-1"
                name="tabs-two"
                type="radio"
                checked="checked"
              />

              <div class="services__list1">
                <!-- presentation du prestataire start -->
                <div class="row">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-3">
                    <div class="card p-3" style="width: 15rem !important">
                      <img
                        v-bind:src="sActeurData.photo_url"
                        alt="img"
                        class="img-thumbnail"
                        width="200"
                      />
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <h4 class="mt-4">{{ sActeurData.full_name }}</h4>

                    <!-- Description (pas disponible pour le moment) à Afficher s'il y en a -->
                    <p class="text-muted mb-3 font-size-sm">
                      {{ sActeurData.description }}
                    </p>
                    <!-- Description (pas disponible pour le moment) à Afficher s'il y en a -->

                    <p class="text mb-3">
                      Je propose des services de :
                      <span class="presentation_items"
                        >{{ sActeurData.categorie }}
                        <span style="color: black !important">|</span>
                        {{ sActeurData.metier }}</span
                      >
                    </p>
                    <!-- <p class="text mb-3">Contactez-moi au : <span class="presentation_items">{{ sActeurData.contact }}</span></p> -->
                    <p class="text-muted font-size-sm">
                      Je suis localisé (e) à :
                      <span class="presentation_items">{{
                        sActeurData.adresse
                      }}</span>
                    </p>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
            <!-- tabs 1 end -->

            <!--  tabs 2 start -->
            <div class="tab-2">
              <label for="tab2-2" style="color: #4e585d !important"
                >Mes Services</label
              >
              <input id="tab2-2" name="tabs-two" type="radio" />

              <!-- liste des services du prestataire start -->
              <div class="col-md-8">
                <div v-if="acteurServices.length == 0" class="text-center">
                  <span>Pas de service disponible</span>
                </div>
                <div
                  v-else
                  style="
                    padding-left: 150px !important;
                    padding-right: 150px !important;
                  "
                >
                  <p class="text-center font-size-sm" style="color: #f7a51b">
                    Pour effectuer une demande, veuillez utiliser l'application
                    mobile s'il vous plaît.
                  </p>
                  <table class="table table-bordered shadow-sm">
                    <thead>
                      <tr>
                        <th>Nom du service</th>
                        <th>Prix minimal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="data in acteurServices" :key="data.id">
                        <td>{{ data.service.libelle }}</td>
                        <td
                          style="color: #f7a51b !important; font-weight: bolder"
                        >
                          {{ data.prix_min }} f cfa
                        </td>
                        <!-- <input class="form-check-input me-1" type="checkbox" value="" aria-label="..." /> <td><button type="button" class="btn" style="color: #ffffff; font-size: xx-small; background-image: linear-gradient(to right, #dedede, #61C7F2)  !important;"
                              data-bs-toggle="modal" data-bs-target="#staticBackdrop">Faire une demande</button>
                          </td> -->
                      </tr>
                    </tbody>
                  </table>
                  <!-- pagination start -->
                  <nav
                    aria-label="Page navigation example"
                    style="float: right !important"
                  >
                    <ul class="pagination">
                      <li class="page-item">
                        <router-link class="page-link" to="" aria-label="Next">
                          <span aria-hidden="true">&laquo;</span>
                        </router-link>
                      </li>
                      <li class="page-item">
                        <router-link class="page-link" to="" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </router-link>
                      </li>
                    </ul>
                  </nav>
                  <!-- pagination end -->
                </div>
              </div>
              <!-- servicesliste end -->
            </div>
            <!-- tabs 2 end -->
          </div>
          <!-- ends tabs -->
        </div>
        <!-- end  main body -->
      </div>
      <!-- end  container -->

      <!-- Footer Start -->
      <Footer />
      <!-- Footer End -->

      <!-- modal réservation start -->
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Réservation</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <section class="">
                <div>
                  <span style="font-size: 15px; color: #f7a51b"
                    >- Manucure et pose vernis semi-permanant
                  </span>
                </div>

                <div class="py-2 mt-3 text-center" style="font-size: 13px">
                  Veuillez sélectionner la date et l'heure à laquelle vous
                  souhaiterez avoir cette prestation
                </div>

                <form class="mt-2">
                  <div class="mb-3 row">
                    <label for="inputPassword" class="col-sm-2 col-form-label"
                      >Date</label
                    >
                    <div class="col-sm-10">
                      <input
                        type="date"
                        class="form-control"
                        id="inputPassword"
                      />
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <label for="inputPassword" class="col-sm-2 col-form-label"
                      >Heure</label
                    >
                    <div class="col-sm-10">
                      <input
                        type="time"
                        class="form-control"
                        id="inputPassword"
                      />
                    </div>
                  </div>
                </form>
              </section>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                style="color: #ffffff; background-color: #f7a51b"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="submit"
                class="btn"
                style="color: #ffffff; background-color: #61c7f2"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal réservation start -->
    </div>
    <!-- end  container-fluid -->
  </div>
  <!-- end  div.offre -->
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
// import $ from jquery;

export default {
  name: "Offre",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      loading: false,
      acteurServices: [],
      sActeurData: [],
    };
  },
  mounted() {
    this.getServicesList();
    var storageData = localStorage.getItem("selectedUserData"),
      proxyToJson = Object.assign({}, JSON.parse(storageData));
    //console.log("proxyToJson ", proxyToJson);
    this.sActeurData = proxyToJson;
  },
  methods: {
    async getServicesList() {
      this.loading = true;

      var data = {
        prestataireId: this.$route.params.prestataireId,
        metierId: this.$route.params.metierId,
      };
      await this.$store
        .dispatch("staticdata/fetchPrestataireService", data)
        .then(
          (response) => {
            this.loading = false;
            this.acteurServices = response.data;
            //console.log("response ", response.data);
          },
          (error) => {
            this.loading = false;
            console.log("error--- ", error);
          }
        );
    },
  },
};
</script>

<style scoped>
body {
  background-color: #e2e8f0;
}

.breadcrumb .active {
  color: #61c7f2 !important;
}
.breadcrumb-item {
  color: #4e585d !important;
}

.page-link {
  color: #61c7f2 !important;
}
.page-link:active {
  color: #f7a51b !important;
}
.main-body {
  padding: 15px;
}
.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

h1 {
  text-align: center;
  font-weight: 700;
  color: rgb(70, 70, 70);
  margin-bottom: 0px;
  letter-spacing: 2px;
}

.list {
  background: #fcfcfc;
  border-radius: 10px;
  position: relative;
  display: block;
  cursor: pointer;
  transition: 0.3s;
}
.presentation_items {
  font-size: 15px !important;
  font-weight: bolder !important;
  color: #f7a51b !important;
}

h1 {
  font-size: 40px;
  line-height: 0.8em;
  color: rgba(255, 255, 255, 0.2);
}

.tabs {
  display: block;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  overflow: hidden;
}
.tabs [class^="tab"] label,
.tabs [class*=" tab"] label {
  color: #efedef;
  cursor: pointer;
  display: block;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1em;
  padding: 2rem 0;
  text-align: center;
}
.tabs [class^="tab"] [type="radio"],
.tabs [class*=" tab"] [type="radio"] {
  border-bottom: 1px solid rgba(239, 237, 239, 0.5);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.tabs [class^="tab"] [type="radio"]:hover,
.tabs [class^="tab"] [type="radio"]:focus,
.tabs [class*=" tab"] [type="radio"]:hover,
.tabs [class*=" tab"] [type="radio"]:focus {
  border-bottom: 1px solid #f7a51b;
}
.tabs [class^="tab"] [type="radio"]:checked,
.tabs [class*=" tab"] [type="radio"]:checked {
  border-bottom: 2px solid #f7a51b;
}
.tabs [class^="tab"] [type="radio"]:checked + div,
.tabs [class*=" tab"] [type="radio"]:checked + div {
  opacity: 1;
}
.tabs [class^="tab"] [type="radio"] + div,
.tabs [class*=" tab"] [type="radio"] + div {
  display: block;
  opacity: 0;
  padding: 2rem 0;
  width: 90%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.tabs .tab-2 {
  width: 50%;
}
.tabs .tab-2 [type="radio"] + div {
  width: 200%;
  margin-left: 200%;
}
.tabs .tab-2 [type="radio"]:checked + div {
  margin-left: 0;
}
.tabs .tab-2:last-child [type="radio"] + div {
  margin-left: 100%;
}
.tabs .tab-2:last-child [type="radio"]:checked + div {
  margin-left: -100%;
}
</style>
