import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ?
    { status: { loggedIn: true }, user } :
    { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                (user) => {
                    commit("loginSuccess", user);
                    return Promise.resolve(user);
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        verirfyotp({ commit }, data) {
            return AuthService.otpVerification(data).then(
                (response) => {
                    commit("loginSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        forgotPwd({ commit }, phone) {
            return AuthService.sendForgotPasswordRequest(phone).then(
                (response) => {
                    commit("loginSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        forgotPwdVerifyOpt({ commit }, otp) {
            return AuthService.otpVerificationToResetPassword(otp).then(
                (response) => {
                    commit("loginSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        forgotPwdSetNewPassword({ commit }, d) {
            return AuthService.passwordReset(d).then(
                (response) => {
                    commit("loginSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        resendOtpRequest({ commit }, d) {
            return AuthService.resendOtp(d).then(
                (response) => {
                    commit("loginSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit("logout");
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
    },
};