import axios from 'axios'
import { baseURL } from "../config/apiBase"

class StaticDataService {
    getCategories() {
        return axios.get(
                baseURL + 'categories/liste'
            )
            .then(response => {
                return response.data;
            });
    }

    getPrestataireServices(requestData) {
        var authData = Object.assign({}, JSON.parse(localStorage.getItem('authUser'))),
            authToken = authData.token;
        return axios.get(
            baseURL + 'prestataire/' + requestData.prestataireId + '/metier/'+ requestData.metierId + '/services',{
                headers: {
                    "Accept": 'application/json',
                    "Authorization": 'Bearer ' + authToken,
                }
            }
        )
        .then(response => {
            return response.data;
        });
    }

    getSubCategory(categoryId) {
        return axios.get(
                baseURL + 'categorie/' + categoryId + '/sous-categories'
            )
            .then(response => {
                return response.data;
            });
    }

    getCategoryAndSubCategory() {
        return axios.get(
                baseURL + 'categories'
            )
            .then(response => {
                return response.data;
            });
    }

    searchPrestataire(sousCategorieId) {
        return axios.get(
                baseURL + 'sous-categorie/' + sousCategorieId + '/prestataires'
            )
            .then(response => {
                return response.data;
            });
    }
}

export default new StaticDataService();