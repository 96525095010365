<template>
  <div>
    <input type="checkbox" id="sidebar-toggle" />
    <UserSideBar :typeUser="typeActeur" />

    <div class="main-content">
      <UserNavbar :data="userData" />
      <!-- contenu start -->
      <main>
        <div class="container">
          <div class="main-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="d-flex flex-column align-items-center text-center"
                    >
                      <br />
                      <div v-if="userData == null" class="">
                        <free-style-shimmer
                          :is-loading="true"
                          height="100px"
                          width="100px"
                          border-radius="50px"
                          color="#bdbdbd"
                        />
                      </div>
                      <div
                        v-else-if="
                          userData != null &&
                          userData.photo == 'http://195.154.51.46:8117/'
                        "
                        class="rounded-circle border d-flex justify-content-center align-items-center"
                        style="
                          width: 100px;
                          height: 100px;
                          border-color: #f7a51b !important;
                        "
                        alt="Avatar"
                      >
                        <font-awesome-icon
                          style="width: 50px; height: 50px; color: #61c7f2"
                          icon="user"
                        />
                      </div>
                      <div v-else>
                        <img
                          :src="userData.photo"
                          :alt="userData.nom"
                          class="rounded-circle p-1 bg-warning"
                          width="110"
                        />
                      </div>
                      <div v-if="userData == null" class="mt-4">
                        <paragraph-shimmer
                          :is-loading="true"
                          :lines="4"
                          :random-size="true"
                        />
                      </div>
                      <div v-else class="mt-4">
                        <h5>{{ userData.nom }} {{ userData.prenoms }}</h5>
                        <b
                          style="color: #61c7f2 !important"
                          class="text-primary mb-1"
                        >
                          {{ typeActeur }} - {{ userType }}
                          <!-- {{ typeActeur }} | {{ userType }} -->
                        </b>
                        <p class="text-muted font-size-sm">
                          {{ userData.adresse }}
                        </p>
                        <br />

                        <hr class="my-4" />
                        <div class="row">
                          <div class="col-md-6">
                            <button
                              type="button"
                              @click.prevent="showPasswordEdotingModal"
                              class="btn btn-sm btn-block w-100 btn-inverse"
                              style="
                                color: #fff;
                                background: #61c7f2 !important;
                                border-color: #61c7f2 !important;
                              "
                            >
                              <font-awesome-icon icon="edit" />
                              Mot de passe
                            </button>
                          </div>
                          <div class="col-md-6">
                            <button
                              class="btn btn-sm btn-block w-100 btn-inverse"
                              style="
                                color: #fff;
                                background: #f7a51b !important;
                                border-color: #f7a51b !important;
                              "
                              type="button"
                              @click="toggleModal"
                            >
                              <font-awesome-icon icon="edit" />
                              Avatar
                            </button>
                          </div>
                          <div class="col-md-12 mt-5">
                            <button
                              type="button"
                              @click="displayAccountDeleteModal"
                              class="btn btn-sm btn-block w-100 btn-outline"
                              style="
                                color: #dadada;
                                background: #ffffff !important;
                                border-color: #dadada !important;
                              "
                            >
                              <font-awesome-icon icon="trash" />
                              Supprimer mon compte
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="text-center">
                      <!-- <GoogleMap
                        api-key="AIzaSyDjV8aSDctyyV6xcWKPJS1C77AjyI9H7pg"
                        style="width: 100%; height: 200px"
                        :center="center"
                        :zoom="15"
                      >
                        <Marker :options="markerOptions" />
                      </GoogleMap> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-8">
                <!-- prestataire particulier et entrprise -->
                <!-- <div
                  v-if="typeActeur == 'prestataire' && userType == 'Entreprise'"
                  class="prestataire-entreprise-form"
                >
                  <form @submit.prevent="saveChanges">
                    <div class="card">
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Nom de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Contact de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="submit"
                              class="btn px-4"
                              style="
                                background-color: #61c7f2 !important;
                                color: #ffffff !important;
                              "
                              value="Enrégistrer"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> -->
                <!--  -->
                <!-- <div
                  v-if="
                    typeActeur == 'prestataire' && userType == 'Particulier'
                  "
                  class="prestataire-particulier-form"
                >
                  <form @submit.prevent="saveChanges">
                    <div class="card">
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Niveau d'étude</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option value="Femme">Sécondaire</option>
                              <option value="Homme">Supérieur</option>
                            </select>
                          </div>
                        </div>
                        <br />
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="submit"
                              class="btn px-4"
                              style="
                                background-color: #61c7f2 !important;
                                color: #ffffff !important;
                              "
                              value="Enrégistrer"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> -->
                <!-- prestataire particulier et entrprise -->

                <!-- recruteur particulier et entrprise -->
                <!-- <div
                  v-if="typeActeur == 'recruteur' && userType == 'Entreprise'"
                  class="recruteur-entreprise-form"
                >
                  <form @submit.prevent="saveChanges">
                    <div class="card">
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Nom de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Contact de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="submit"
                              class="btn px-4"
                              style="
                                background-color: #61c7f2 !important;
                                color: #ffffff !important;
                              "
                              value="Enrégistrer"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> -->
                <!--  -->
                <!-- <div
                  v-if="typeActeur == 'recruteur' && userType == 'Particulier'"
                  class="recruteur-particulier-form"
                >
                  <form @submit.prevent="saveChanges">
                    <div class="card">
                      <div class="card-body">
                        <p>partie Récruteur particulier</p>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="submit"
                              class="btn px-4"
                              style="
                                background-color: #61c7f2 !important;
                                color: #ffffff !important;
                              "
                              value="Enrégistrer"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> -->

                <!-- recruteur particulier et entrprise -->

                <form @submit.prevent="saveChanges" class="mb-3">
                  <div class="card">
                    <div class="card-body">
                      <p class="mb-2 text-center" style="font-weight: bold">
                        Modifiez vos informations
                      </p>
                      <hr />
                      <br /><br />
                      <!-- <div class="row mb-3">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Nom</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        <input type="text" class="form-control" :value="userData != null ? userData.nom : ''">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Prénoms</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        <input type="text" class="form-control" :value="userData != null ? userData.prenoms : ''">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Genre</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        <select class="form-select" aria-label="Default select example">
                                            <option value="Femme">Femme</option>
                                            <option value="Homme">Homme</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Contact</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        <input type="text" class="form-control" :value="userData != null ? userData.user.phone : ''">
                                    </div>
                                </div> -->
                      <!-- DEBUT : AJOUT DE CHAMP SUPPLEMENTAIRE -->
                      <div
                        v-if="
                          typeActeur == 'prestataire' &&
                          userType == 'Entreprise'
                        "
                        class="prestataire-entreprise-custom-inputs"
                      >
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Nom de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="text"
                              class="form-control"
                              v-model="upNom"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Contact de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="text"
                              class="form-control"
                              v-model="upContact"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                v-model="upDescription"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          typeActeur == 'prestataire' &&
                          userType == 'Particulier'
                        "
                        class="prestataire-particulier-custom-inputs"
                      >
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Niveau d'étude</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              v-model="upNiveau"
                            >
                              <option value="Aucun">Aucun</option>
                              <option value="Secondaire">Secondaire</option>
                              <option value="Supérieur">Supérieur</option>
                              <option value="Elémentaire">Elémentaire</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                v-model="upDescription"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          typeActeur == 'recruteur' && userType == 'Entreprise'
                        "
                        class="recruteur-entreprise-custom-inputs"
                      >
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Nom de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="text"
                              class="form-control"
                              v-model="upNom"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Contact de l'entreprise</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              type="text"
                              class="form-control"
                              v-model="upContact"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                v-model="upDescription"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          typeActeur == 'recruteur' && userType == 'Particulier'
                        "
                        class="recruteur-particulier-custom-inputus"
                      >
                        <div class="row mb-3">
                          <div class="col-sm-3">
                            <h6 class="mb-0">
                              Ajouter une description de vous
                            </h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <div class="mb-3">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                v-model="upDescription"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- FIN : AJOUT DE CHAMP SUPPLEMENTAIRE -->
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Email</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            class="form-control"
                            :value="userData != null ? userData.user.email : ''"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Adresse</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <GMapAutocomplete
                            :placeholder="
                              userData != null ? userData.adresse : ''
                            "
                            @place_changed="setPlace"
                            class="form-control"
                            v-model="addresse"
                          >
                          </GMapAutocomplete>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="submit"
                            class="btn btn-sm px-3"
                            style="
                              background-color: #f7a51b !important;
                              color: #ffffff !important;
                            "
                            value="Enrégistrer les modifications"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div
        ref="modal"
        class="modal fade"
        :class="{ show, 'd-block': active }"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Modification d'avatar</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="toggleModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container">
                <div class="text-center">
                  <img
                    v-if="imgSrc != ''"
                    style="width: 300px; height: 300px"
                    class="img-fluid img-thumbnail rounded mx-auto d-block"
                    id="image"
                    :src="imgSrc"
                  />
                  <br />
                  <div class="text-center">
                    <input
                      accept=".jpg,.jpeg,.png"
                      type="file"
                      @change="preview"
                      name="avatarImg"
                      id="avatarImg"
                      ref="file"
                    />
                  </div>
                  <div class="text-center mt-4">
                    <button
                      type="submit"
                      @click.prevent="changeMyAvatar"
                      class="btn btn-block btn-sm w-100 btn-inverse"
                      style="
                        color: #fff;
                        background: #f7a51b !important;
                        border-color: #f7a51b !important;
                      "
                    >
                      Soumettre
                    </button>
                  </div>
                  <div v-if="uploaded != ''">Image url -> {{ uploaded }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="active" class="modal-backdrop fade show"></div>
      <!-- contenu end -->

      <!-- modal de suppression -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Suppression de compte
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="mb-3">
                  <label for="recipient-name" class="col-form-label"
                    >Mot de passe</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="recipient-name"
                    placeholder="Entrer votre mot de passe"
                  />
                </div>

                <!-- à afficher si le mdp est correct -->
                <div class="mb-3">
                  <label for="recipient-name" class="col-form-label"
                    >Confirmer le mot de passe</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="recipient-name"
                    placeholder="Confirmer votre mot de passe"
                  />
                </div>
                <!-- à afficher si le mdp est correct -->
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                style="background-color: #61c7f2; color: #ffffff"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn"
                style="background-color: #f7a51b; color: #ffffff"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserNavbar from "../../components/UserNavbar.vue";
import UserSideBar from "../../components/UserSideBar.vue";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";
//import { GoogleMap, Marker } from "vue3-google-map";
export default {
  name: "UserProfil",
  components: {
    UserNavbar,
    UserSideBar,
    //GoogleMap,
    //Marker,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading: false,
      userData: null,
      userType: "",
      typeActeur: "",
      addresse: "",
      markerOptions: "",
      localisation: null,
      nom: "",
      prenoms: "",
      phone: "",
      email: "",
      niveau: "",
      genre: "",
      upDescription: "",
      upContact: "",
      upNom: "",
      upNiveau: "",
      message: "",
      isModalVisible: false,
      active: false,
      show: false,
      imgSrc: "",
      uploaded: "",
      currentImage: undefined,
      center: { lat: 5.463412568886461, lng: -3.835258495814968 },
    };
  },
  mounted() {
    this.fetchUserInfos();
  },
  methods: {
    toggleModal() {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
      setTimeout(() => (this.show = !this.show), 10);
    },
    preview(e) {
      let imgSrc = URL.createObjectURL(e.target.files[0]);
      this.currentImage = e.target.files[0];
      this.imgSrc = imgSrc;
    },
    async changeMyAvatar() {
      var data = {
        photo: this.currentImage,
      };
      //console.log("Avatar image ", this.currentImage);
      await this.$store.dispatch("user/changeUserAvatar", data).then(
        (response) => {
          console.log("change avatar ", response);

          const body = document.querySelector("body");
          this.toast.success("Mise à jour éffectuer avec succès ", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.fetchUserInfos();
          this.active = !this.active;
          body.classList.remove("modal-open");

          this.imgSrc = "";
          setTimeout(() => (this.show = !this.show), 10);
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          Swal.fire({
            title: "Oups :) une erreur s'est produite",
            text: this.message,
            icon: "error",
          });
        }
      );
    },
    setPlace(event) {
      var lat = event.geometry.viewport.Ab.h;
      var lng = event.geometry.viewport.Ra.h;

      this.localisation = lng + ";" + lat;
      this.addresse = event.name;
    },
    async showPasswordEdotingModal() {
      const { value: formValues } = await Swal.fire({
        title: "Mise à jour de mot de passe",
        html:
          '<input id="oldPassword" class="swal2-input" placeholder="Mot de passe actuel">' +
          '<input id="newPassword" class="swal2-input" placeholder="Nouveau mot de passe">' +
          '<input id="confNewPassword" class="swal2-input" placeholder="Confirmation">',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: "Enregistrer",
        cancelButtonText: "Annuler",
        preConfirm: () => {
          return [
            document.getElementById("oldPassword").value,
            document.getElementById("newPassword").value,
            document.getElementById("confNewPassword").value,
          ];
        },
      });

      if (formValues) {
        var currentPass = formValues[0],
          newPassword = formValues[1],
          confNewPass = formValues[2];
        if (currentPass != "" && newPassword != "" && confNewPass != "") {
          var inputData = {
            current_password: currentPass,
            new_password: newPassword,
            new_confirm_password: confNewPass,
          };
          await this.$store
            .dispatch("user/updatePasswordRequest", inputData)
            .then(
              (response) => {
                if (response.success) {
                  this.toast.success(response.message, {
                    position: "top-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                  localStorage.removeItem("authUser");
                  window.location.reload();
                }
              },
              (error) => {
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();

                Swal.fire({
                  title: "Oups :) une erreur s'est produite",
                  text:
                    (error.response &&
                      error.response.data &&
                      error.response.data.message +
                        " " +
                        JSON.stringify(error.response.data.data)) ||
                    error.data ||
                    error.message ||
                    error.toString(),
                  icon: "error",
                });
              }
            );
        } else {
          Swal.fire(
            "Certains champ sont vide",
            "Veuillez renseigner tout les champs",
            "error"
          );
        }
      }
    },
    async fetchUserInfos() {
      var authData = Object.assign(
        {},
        JSON.parse(localStorage.getItem("authUser"))
      );
      this.typeActeur = authData.user.acteur;
      this.userType = authData.user.type;

      await this.$store.dispatch("user/retrieveUserInfo").then(
        (response) => {
          var delta = {
            lat: parseFloat(response.data.localisation.lat),
            lng: parseFloat(response.data.localisation.long),
          };
          this.center = delta;
          this.markerOptions = {
            position: delta,
            label: "",
            title: response.data.adresse,
          };
          this.userData = response.data;

          if (this.typeActeur == "prestataire") {
            this.upNom = this.userData.prestataire.nom_entreprise;
            this.upContact = this.userData.prestataire.contact_entreprise;
            this.upDescription = this.userData.prestataire.description;
            this.upNiveau = this.userData.prestataire.niveau;
          } else if (this.typeActeur == "recruteur") {
            this.upNom = this.userData.recruteur.nom_entreprise;
            this.upContact = this.userData.recruteur.contact_entreprise;
            this.upDescription = this.userData.recruteur.description;
          }
          //console.log("CENTER ", this.userData);
        },
        (error) => {
          console.log("fetchUserInfos error--- ", error);
        }
      );
    },
    async displayAccountDeleteModal() {
      Swal.fire({
        title: "Suppression de compte",
        input: "password",
        inputLabel: "Renseigner votre mot de passe puis cliquez sur continuer",
        inputPlaceholder: "Saisissez votre mot de passe",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Continuer",
        cancelButtonText: "Annuler",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (password) => {
          return this.$store
            .dispatch("user/verifyPasswordForAccountDeletion", password)
            .then(
              (response) => {
                console.log(
                  "response verifyPasswordForAccountDeletion ",
                  response
                );
                return response;
              },
              (error) => {
                console.log("error verifyPasswordForAccountDeletion ", error);
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                Swal.showValidationMessage(this.message);
              }
            );
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            title: "Attention",
            html: "Vous êtes sur le point de supprimer votre compte, voulez-vous continuer ?",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Annuler",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: () => {
              return this.$store.dispatch("user/confirmAccountDeletion").then(
                (response1) => {
                  console.log("response confirmAccountDeletion ", response1);
                  return response1;
                },
                (error1) => {
                  console.log("error confirmAccountDeletion ", error1);
                  this.message =
                    (error1.response &&
                      error1.response.data &&
                      error1.response.data.message) ||
                    error1.message ||
                    error1.toString();
                  Swal.showValidationMessage(this.message);
                }
              );
            },
          }).then((result1) => {
            if (result1.isConfirmed) {
              // Deconnexion
              this.$store.dispatch("auth/logout");
              window.location.reload();
            }
          });
        }
      });
    },
    async saveChanges() {
      var locationData = {
        localisation: this.localisation,
        addresse: this.addresse,
      };
      var moreInfosData = {
        description: this.upDescription,
        contact_entreprise: this.upContact,
        nom_entreprise: this.upNom,
        niveau: this.upNiveau,
      };
      if (this.localisation != null) {
        //Mise à jour de la localisation
        await this.$store
          .dispatch("user/changeLocationRequest", locationData)
          .then(
            (response) => {
              this.toast.success(
                "Votre nouvelle adresse est : " + response.data.adresse,
                {
                  position: "top-right",
                  timeout: 2000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                }
              );
            },
            (error) => {
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.loading = false;

              Swal.fire({
                title:
                  "Oups :) une erreur s'est produite lors de la mise à jour localisation et addresse",
                text:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message +
                      " " +
                      JSON.stringify(error.response.data.data)) ||
                  error.data ||
                  error.message ||
                  error.toString(),
                icon: "error",
              });
            }
          );
      }
      // TODO : mise à jour des autres informations
      await this.$store.dispatch("user/editUserInfos", moreInfosData).then(
        (response) => {
          console.log(" --- ", response);
          this.toast.success(response.message, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;

          Swal.fire({
            title: "Oups :) une erreur s'est produite",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message +
                  " " +
                  JSON.stringify(error.response.data.data)) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );

      await this.fetchUserInfos();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
:root {
  --main-color: #027581;
  --color-dark: #1d2231;
  --text-grey: #8390a2;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  box-sizing: border-box;
}

.main-content {
  position: relative;
  margin-left: 200px;
  transition: margin-left 500ms;
}

main {
  margin-top: 60px;
  background: #f1f5f9;
  min-height: 90vh;
  padding: 1rem 3rem;
}

@media only screen and (max-width: 1200px) {
  .main-content {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 450px) {
  main {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
  margin-right: 0.5rem !important;
}
</style>
